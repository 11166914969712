// @generated by protoc-gen-es v1.6.0 with parameter "target=ts"
// @generated from file perkup/v1/organization.proto (package perkup.v1, syntax proto3)
/* eslint-disable */
// @ts-nocheck

import type { BinaryReadOptions, FieldList, JsonReadOptions, JsonValue, PartialMessage, PlainMessage } from "@bufbuild/protobuf";
import { Message, proto3, protoInt64, Timestamp } from "@bufbuild/protobuf";
import { Individual_Role } from "./individual_pb.js";
import { ProductVariant_SourceType } from "./product_variant_pb.js";
import { OrderFulfillment_FulfillmentStatus_Enum, ShipmentStatus_Enum } from "./vendor_pb.js";

/**
 * Firestore /orgAccessTokens/:id
 *
 * @generated from message perkup.v1.OrgAccessTokens
 */
export class OrgAccessTokens extends Message<OrgAccessTokens> {
  /**
   * @generated from field: string slackAccessToken = 1;
   */
  slackAccessToken = "";

  constructor(data?: PartialMessage<OrgAccessTokens>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrgAccessTokens";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "slackAccessToken", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgAccessTokens {
    return new OrgAccessTokens().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgAccessTokens {
    return new OrgAccessTokens().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgAccessTokens {
    return new OrgAccessTokens().fromJsonString(jsonString, options);
  }

  static equals(a: OrgAccessTokens | PlainMessage<OrgAccessTokens> | undefined, b: OrgAccessTokens | PlainMessage<OrgAccessTokens> | undefined): boolean {
    return proto3.util.equals(OrgAccessTokens, a, b);
  }
}

/**
 * Firestore /organizations/:id
 *
 * @generated from message perkup.v1.Organization
 */
export class Organization extends Message<Organization> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: string customer_id = 6;
   */
  customerId = "";

  /**
   * @generated from field: string display_currency = 7;
   */
  displayCurrency = "";

  /**
   * @generated from field: optional string finch_access_token = 8;
   */
  finchAccessToken?: string;

  /**
   * @generated from field: optional string finch_status = 9;
   */
  finchStatus?: string;

  /**
   * @generated from field: optional google.protobuf.Timestamp finch_sync_date = 10;
   */
  finchSyncDate?: Timestamp;

  /**
   * @generated from field: optional string logo_url = 11;
   */
  logoUrl?: string;

  /**
   * @generated from field: string name = 12;
   */
  name = "";

  /**
   * @generated from field: optional string primary_color = 14;
   */
  primaryColor?: string;

  /**
   * @generated from field: optional bool slack_connected = 18;
   */
  slackConnected?: boolean;

  /**
   * @generated from field: perkup.v1.Organization.SubscriptionStatus subscription_status = 20;
   */
  subscriptionStatus = Organization_SubscriptionStatus.SUBSCRIPTION_STATUS_UNSPECIFIED;

  /**
   * @generated from field: optional perkup.v1.Organization.ZoneInfo zoneinfo = 21;
   */
  zoneinfo?: Organization_ZoneInfo;

  /**
   * @generated from field: perkup.v1.Organization.Onboarding onboarding = 22;
   */
  onboarding?: Organization_Onboarding;

  /**
   * @generated from field: optional bool show_name_logo = 24;
   */
  showNameLogo?: boolean;

  /**
   * @generated from field: optional perkup.v1.Organization.WorkOSStatus workos_status = 25;
   */
  workosStatus?: Organization_WorkOSStatus;

  /**
   * @generated from field: optional string workos_org_id = 26;
   */
  workosOrgId?: string;

  /**
   * @generated from field: optional perkup.v1.Organization.Settings settings = 27;
   */
  settings?: Organization_Settings;

  /**
   * Default AccountID is used to evaluate which AccountID should be used for a Program.
   *  when an Organization is created there is short duration when this is an empty string "".
   *
   * @generated from field: string default_account_id = 29;
   */
  defaultAccountId = "";

  /**
   * Prepaid AccountID is used to evaluate which AccountID should be used for a prepaid adjustment transaction.
   * these adjustments are possible in the following scenario:
   *  - an organization has a prepaid item in their public store
   *
   *    then an individual buys the item with personal funds
   *
   *    so we need to payback those funds to the organization
   *
   * @generated from field: optional string prepaid_account_id = 36;
   */
  prepaidAccountId?: string;

  /**
   * @generated from field: perkup.v1.Organization.SyncSettings hris_sync_settings = 30;
   */
  hrisSyncSettings?: Organization_SyncSettings;

  /**
   * @generated from field: repeated string core_values = 31;
   */
  coreValues: string[] = [];

  /**
   * @generated from field: optional string stripe_connect_account_id = 32;
   */
  stripeConnectAccountId?: string;

  /**
   * the key is meant to be StripeConnectAccountCapability enum type string
   *
   * @generated from field: map<string, perkup.v1.Organization.StripeConnectAccountCapabilityStatus.Enum> stripe_connect_account_capabilities = 33;
   */
  stripeConnectAccountCapabilities: { [key: string]: Organization_StripeConnectAccountCapabilityStatus_Enum } = {};

  /**
   * @generated from field: optional string swag_banner_url = 34;
   */
  swagBannerUrl?: string;

  /**
   * Assume all invoices for org are paid unless status is void
   *
   * @generated from field: optional bool credit_open_invoices = 35;
   */
  creditOpenInvoices?: boolean;

  /**
   * Email of the CSM assigned to the organization
   *
   * @generated from field: optional string csm_email = 37;
   */
  csmEmail?: string;

  constructor(data?: PartialMessage<Organization>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Organization";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 6, name: "customer_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "display_currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "finch_access_token", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "finch_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 10, name: "finch_sync_date", kind: "message", T: Timestamp, opt: true },
    { no: 11, name: "logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 12, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "primary_color", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 18, name: "slack_connected", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 20, name: "subscription_status", kind: "enum", T: proto3.getEnumType(Organization_SubscriptionStatus) },
    { no: 21, name: "zoneinfo", kind: "message", T: Organization_ZoneInfo, opt: true },
    { no: 22, name: "onboarding", kind: "message", T: Organization_Onboarding },
    { no: 24, name: "show_name_logo", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 25, name: "workos_status", kind: "enum", T: proto3.getEnumType(Organization_WorkOSStatus), opt: true },
    { no: 26, name: "workos_org_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 27, name: "settings", kind: "message", T: Organization_Settings, opt: true },
    { no: 29, name: "default_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 36, name: "prepaid_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 30, name: "hris_sync_settings", kind: "message", T: Organization_SyncSettings },
    { no: 31, name: "core_values", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 32, name: "stripe_connect_account_id", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 33, name: "stripe_connect_account_capabilities", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "enum", T: proto3.getEnumType(Organization_StripeConnectAccountCapabilityStatus_Enum)} },
    { no: 34, name: "swag_banner_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 35, name: "credit_open_invoices", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 37, name: "csm_email", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization {
    return new Organization().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization {
    return new Organization().fromJsonString(jsonString, options);
  }

  static equals(a: Organization | PlainMessage<Organization> | undefined, b: Organization | PlainMessage<Organization> | undefined): boolean {
    return proto3.util.equals(Organization, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Organization.SubscriptionStatus
 */
export enum Organization_SubscriptionStatus {
  /**
   * @generated from enum value: SUBSCRIPTION_STATUS_UNSPECIFIED = 0;
   */
  SUBSCRIPTION_STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: active = 1;
   */
  active = 1,

  /**
   * @generated from enum value: inactive = 2;
   */
  inactive = 2,

  /**
   * @generated from enum value: canceled = 3;
   */
  canceled = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Organization_SubscriptionStatus)
proto3.util.setEnumType(Organization_SubscriptionStatus, "perkup.v1.Organization.SubscriptionStatus", [
  { no: 0, name: "SUBSCRIPTION_STATUS_UNSPECIFIED" },
  { no: 1, name: "active" },
  { no: 2, name: "inactive" },
  { no: 3, name: "canceled" },
]);

/**
 * @generated from enum perkup.v1.Organization.WorkOSStatus
 */
export enum Organization_WorkOSStatus {
  /**
   * @generated from enum value: WORKOS_STATUS_UNSPECIFIED = 0;
   */
  WORKOS_STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: activated = 1;
   */
  activated = 1,

  /**
   * @generated from enum value: deactivated = 2;
   */
  deactivated = 2,

  /**
   * @generated from enum value: deleted = 3;
   */
  deleted = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Organization_WorkOSStatus)
proto3.util.setEnumType(Organization_WorkOSStatus, "perkup.v1.Organization.WorkOSStatus", [
  { no: 0, name: "WORKOS_STATUS_UNSPECIFIED" },
  { no: 1, name: "activated" },
  { no: 2, name: "deactivated" },
  { no: 3, name: "deleted" },
]);

/**
 * @generated from enum perkup.v1.Organization.StripeConnectAccountCapability
 */
export enum Organization_StripeConnectAccountCapability {
  /**
   * @generated from enum value: STRIPE_CONNECT_ACCOUNT_CAPABILITY_UNSPECIFIED = 0;
   */
  STRIPE_CONNECT_ACCOUNT_CAPABILITY_UNSPECIFIED = 0,

  /**
   * @generated from enum value: cardIssuing = 1;
   */
  cardIssuing = 1,

  /**
   * @generated from enum value: cardPayments = 2;
   */
  cardPayments = 2,

  /**
   * @generated from enum value: transfers = 3;
   */
  transfers = 3,
}
// Retrieve enum metadata with: proto3.getEnumType(Organization_StripeConnectAccountCapability)
proto3.util.setEnumType(Organization_StripeConnectAccountCapability, "perkup.v1.Organization.StripeConnectAccountCapability", [
  { no: 0, name: "STRIPE_CONNECT_ACCOUNT_CAPABILITY_UNSPECIFIED" },
  { no: 1, name: "cardIssuing" },
  { no: 2, name: "cardPayments" },
  { no: 3, name: "transfers" },
]);

/**
 * @generated from message perkup.v1.Organization.ZoneInfo
 */
export class Organization_ZoneInfo extends Message<Organization_ZoneInfo> {
  /**
   * @generated from field: int64 dst_offset = 1;
   */
  dstOffset = protoInt64.zero;

  /**
   * @generated from field: int64 raw_offset = 2;
   */
  rawOffset = protoInt64.zero;

  /**
   * @generated from field: string status = 3;
   */
  status = "";

  /**
   * @generated from field: string time_zone_id = 4;
   */
  timeZoneId = "";

  /**
   * @generated from field: string time_zone_name = 5;
   */
  timeZoneName = "";

  constructor(data?: PartialMessage<Organization_ZoneInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Organization.ZoneInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "dst_offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 2, name: "raw_offset", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
    { no: 3, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "time_zone_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "time_zone_name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization_ZoneInfo {
    return new Organization_ZoneInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization_ZoneInfo {
    return new Organization_ZoneInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization_ZoneInfo {
    return new Organization_ZoneInfo().fromJsonString(jsonString, options);
  }

  static equals(a: Organization_ZoneInfo | PlainMessage<Organization_ZoneInfo> | undefined, b: Organization_ZoneInfo | PlainMessage<Organization_ZoneInfo> | undefined): boolean {
    return proto3.util.equals(Organization_ZoneInfo, a, b);
  }
}

/**
 * @generated from message perkup.v1.Organization.Onboarding
 */
export class Organization_Onboarding extends Message<Organization_Onboarding> {
  /**
   * @generated from field: bool complete = 1;
   */
  complete = false;

  /**
   * @generated from field: int64 page = 2;
   */
  page = protoInt64.zero;

  constructor(data?: PartialMessage<Organization_Onboarding>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Organization.Onboarding";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "complete", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "page", kind: "scalar", T: 3 /* ScalarType.INT64 */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization_Onboarding {
    return new Organization_Onboarding().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization_Onboarding {
    return new Organization_Onboarding().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization_Onboarding {
    return new Organization_Onboarding().fromJsonString(jsonString, options);
  }

  static equals(a: Organization_Onboarding | PlainMessage<Organization_Onboarding> | undefined, b: Organization_Onboarding | PlainMessage<Organization_Onboarding> | undefined): boolean {
    return proto3.util.equals(Organization_Onboarding, a, b);
  }
}

/**
 * @generated from message perkup.v1.Organization.Settings
 */
export class Organization_Settings extends Message<Organization_Settings> {
  /**
   * Members cant access Amazon shopping if true
   *
   * @generated from field: optional bool hide_amazon = 1;
   */
  hideAmazon?: boolean;

  /**
   * Members cant use or create their perk cards if true
   *
   * @generated from field: optional bool hide_perk_card = 2;
   */
  hidePerkCard?: boolean;

  /**
   * Members cant access shopping if true
   *
   * @generated from field: optional bool hide_shopping = 4;
   */
  hideShopping?: boolean;

  /**
   * Members cant shop for gift cards if true
   *
   * @generated from field: optional bool hide_near_cash = 5;
   */
  hideNearCash?: boolean;

  /**
   * Members cant access food gift card shopping if true
   *
   * @generated from field: optional bool hide_food = 6;
   */
  hideFood?: boolean;

  /**
   * Managers cant create new programs from scratch if true - must use templates
   *
   * @generated from field: optional bool hide_start_from_scratch = 7;
   */
  hideStartFromScratch?: boolean;

  /**
   * Managers cant create invoices if true, only admins
   *
   * @generated from field: optional bool hide_manager_invoicing = 8;
   */
  hideManagerInvoicing?: boolean;

  constructor(data?: PartialMessage<Organization_Settings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Organization.Settings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "hide_amazon", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 2, name: "hide_perk_card", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 4, name: "hide_shopping", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 5, name: "hide_near_cash", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 6, name: "hide_food", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 7, name: "hide_start_from_scratch", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "hide_manager_invoicing", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization_Settings {
    return new Organization_Settings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization_Settings {
    return new Organization_Settings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization_Settings {
    return new Organization_Settings().fromJsonString(jsonString, options);
  }

  static equals(a: Organization_Settings | PlainMessage<Organization_Settings> | undefined, b: Organization_Settings | PlainMessage<Organization_Settings> | undefined): boolean {
    return proto3.util.equals(Organization_Settings, a, b);
  }
}

/**
 * SyncSettings for HRIS
 *  overwrite data on individual
 *  sync_* provide optional blocking of sync
 *
 * @generated from message perkup.v1.Organization.SyncSettings
 */
export class Organization_SyncSettings extends Message<Organization_SyncSettings> {
  /**
   * @generated from field: bool email = 1;
   */
  email = false;

  /**
   * @generated from field: bool start_date = 2;
   */
  startDate = false;

  /**
   * @generated from field: bool dob = 3;
   */
  dob = false;

  /**
   * @generated from field: bool manager = 4;
   */
  manager = false;

  /**
   * @generated from field: bool labels = 5;
   */
  labels = false;

  /**
   * Sync Date of Birth
   *  false   -> do not sync
   *  true    -> sync
   *  not set -> sync
   *
   * @generated from field: optional bool sync_dob = 6;
   */
  syncDob?: boolean;

  /**
   * Employment / SubEmployment types to sync
   *  in map     -> use SubEmployment state
   *  not in map -> sync all true
   *
   * @generated from field: map<string, perkup.v1.Organization.SubEmployment> sync_employment = 8;
   */
  syncEmployment: { [key: string]: Organization_SubEmployment } = {};

  /**
   * Sync Personal Emails
   *  false   -> do not sync
   *  true    -> sync
   *  not set -> do not sync
   *
   * @generated from field: optional bool sync_personal_emails = 9;
   */
  syncPersonalEmails?: boolean;

  constructor(data?: PartialMessage<Organization_SyncSettings>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Organization.SyncSettings";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "email", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "start_date", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "dob", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "manager", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "labels", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 6, name: "sync_dob", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 8, name: "sync_employment", kind: "map", K: 9 /* ScalarType.STRING */, V: {kind: "message", T: Organization_SubEmployment} },
    { no: 9, name: "sync_personal_emails", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization_SyncSettings {
    return new Organization_SyncSettings().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization_SyncSettings {
    return new Organization_SyncSettings().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization_SyncSettings {
    return new Organization_SyncSettings().fromJsonString(jsonString, options);
  }

  static equals(a: Organization_SyncSettings | PlainMessage<Organization_SyncSettings> | undefined, b: Organization_SyncSettings | PlainMessage<Organization_SyncSettings> | undefined): boolean {
    return proto3.util.equals(Organization_SyncSettings, a, b);
  }
}

/**
 * SubEmployment type to sync
 *  false   -> do not sync
 *  true    -> sync
 *
 * @generated from message perkup.v1.Organization.SubEmployment
 */
export class Organization_SubEmployment extends Message<Organization_SubEmployment> {
  /**
   * @generated from field: bool full_time = 1;
   */
  fullTime = false;

  /**
   * @generated from field: bool part_time = 2;
   */
  partTime = false;

  /**
   * @generated from field: bool seasonal = 3;
   */
  seasonal = false;

  /**
   * @generated from field: bool temporary_workers = 4;
   */
  temporaryWorkers = false;

  /**
   * @generated from field: bool interns = 5;
   */
  interns = false;

  constructor(data?: PartialMessage<Organization_SubEmployment>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Organization.SubEmployment";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "full_time", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 2, name: "part_time", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 3, name: "seasonal", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "temporary_workers", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 5, name: "interns", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization_SubEmployment {
    return new Organization_SubEmployment().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization_SubEmployment {
    return new Organization_SubEmployment().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization_SubEmployment {
    return new Organization_SubEmployment().fromJsonString(jsonString, options);
  }

  static equals(a: Organization_SubEmployment | PlainMessage<Organization_SubEmployment> | undefined, b: Organization_SubEmployment | PlainMessage<Organization_SubEmployment> | undefined): boolean {
    return proto3.util.equals(Organization_SubEmployment, a, b);
  }
}

/**
 * @generated from message perkup.v1.Organization.StripeConnectAccountCapabilityStatus
 */
export class Organization_StripeConnectAccountCapabilityStatus extends Message<Organization_StripeConnectAccountCapabilityStatus> {
  constructor(data?: PartialMessage<Organization_StripeConnectAccountCapabilityStatus>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Organization.StripeConnectAccountCapabilityStatus";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Organization_StripeConnectAccountCapabilityStatus {
    return new Organization_StripeConnectAccountCapabilityStatus().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Organization_StripeConnectAccountCapabilityStatus {
    return new Organization_StripeConnectAccountCapabilityStatus().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Organization_StripeConnectAccountCapabilityStatus {
    return new Organization_StripeConnectAccountCapabilityStatus().fromJsonString(jsonString, options);
  }

  static equals(a: Organization_StripeConnectAccountCapabilityStatus | PlainMessage<Organization_StripeConnectAccountCapabilityStatus> | undefined, b: Organization_StripeConnectAccountCapabilityStatus | PlainMessage<Organization_StripeConnectAccountCapabilityStatus> | undefined): boolean {
    return proto3.util.equals(Organization_StripeConnectAccountCapabilityStatus, a, b);
  }
}

/**
 * @generated from enum perkup.v1.Organization.StripeConnectAccountCapabilityStatus.Enum
 */
export enum Organization_StripeConnectAccountCapabilityStatus_Enum {
  /**
   * @generated from enum value: STRIPE_CONNECT_ACCOUNT_CAPABILITIES_VALUE_UNSPECIFIED = 0;
   */
  STRIPE_CONNECT_ACCOUNT_CAPABILITIES_VALUE_UNSPECIFIED = 0,

  /**
   * @generated from enum value: active = 1;
   */
  active = 1,

  /**
   * @generated from enum value: inactive = 2;
   */
  inactive = 2,

  /**
   * @generated from enum value: pending = 3;
   */
  pending = 3,

  /**
   * unrequested - not used by PerkUp business logic, but useful to compare with Stripe's data
   *
   * @generated from enum value: unrequested = 4;
   */
  unrequested = 4,
}
// Retrieve enum metadata with: proto3.getEnumType(Organization_StripeConnectAccountCapabilityStatus_Enum)
proto3.util.setEnumType(Organization_StripeConnectAccountCapabilityStatus_Enum, "perkup.v1.Organization.StripeConnectAccountCapabilityStatus.Enum", [
  { no: 0, name: "STRIPE_CONNECT_ACCOUNT_CAPABILITIES_VALUE_UNSPECIFIED" },
  { no: 1, name: "active" },
  { no: 2, name: "inactive" },
  { no: 3, name: "pending" },
  { no: 4, name: "unrequested" },
]);

/**
 * Firestore organization/:id/lists
 *
 * @generated from message perkup.v1.OrgList
 */
export class OrgList extends Message<OrgList> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string name = 2;
   */
  name = "";

  /**
   * @generated from field: optional string description = 3;
   */
  description?: string;

  /**
   * @generated from field: repeated string options = 4;
   */
  options: string[] = [];

  /**
   * @generated from field: perkup.v1.OrgList.ListStatus status = 5;
   */
  status = OrgList_ListStatus.LIST_STATUS_UNSPECIFIED;

  /**
   * @generated from field: google.protobuf.Timestamp created = 6;
   */
  created?: Timestamp;

  constructor(data?: PartialMessage<OrgList>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrgList";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "options", kind: "scalar", T: 9 /* ScalarType.STRING */, repeated: true },
    { no: 5, name: "status", kind: "enum", T: proto3.getEnumType(OrgList_ListStatus) },
    { no: 6, name: "created", kind: "message", T: Timestamp },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgList {
    return new OrgList().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgList {
    return new OrgList().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgList {
    return new OrgList().fromJsonString(jsonString, options);
  }

  static equals(a: OrgList | PlainMessage<OrgList> | undefined, b: OrgList | PlainMessage<OrgList> | undefined): boolean {
    return proto3.util.equals(OrgList, a, b);
  }
}

/**
 * @generated from enum perkup.v1.OrgList.ListStatus
 */
export enum OrgList_ListStatus {
  /**
   * @generated from enum value: LIST_STATUS_UNSPECIFIED = 0;
   */
  LIST_STATUS_UNSPECIFIED = 0,

  /**
   * @generated from enum value: active = 1;
   */
  active = 1,

  /**
   * @generated from enum value: archived = 2;
   */
  archived = 2,
}
// Retrieve enum metadata with: proto3.getEnumType(OrgList_ListStatus)
proto3.util.setEnumType(OrgList_ListStatus, "perkup.v1.OrgList.ListStatus", [
  { no: 0, name: "LIST_STATUS_UNSPECIFIED" },
  { no: 1, name: "active" },
  { no: 2, name: "archived" },
]);

/**
 * Firestore /organizations/:id/invitedUsers
 * TODO: move to a status on individuals
 *
 * @generated from message perkup.v1.InvitedUser
 * @deprecated
 */
export class InvitedUser extends Message<InvitedUser> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string email = 2;
   */
  email = "";

  /**
   * @generated from field: perkup.v1.Individual.Role role = 3;
   */
  role = Individual_Role.ROLE_UNSPECIFIED;

  constructor(data?: PartialMessage<InvitedUser>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.InvitedUser";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "email", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "role", kind: "enum", T: proto3.getEnumType(Individual_Role) },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): InvitedUser {
    return new InvitedUser().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): InvitedUser {
    return new InvitedUser().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): InvitedUser {
    return new InvitedUser().fromJsonString(jsonString, options);
  }

  static equals(a: InvitedUser | PlainMessage<InvitedUser> | undefined, b: InvitedUser | PlainMessage<InvitedUser> | undefined): boolean {
    return proto3.util.equals(InvitedUser, a, b);
  }
}

/**
 * Firestore /restrictedDomains/:id
 *
 * @generated from message perkup.v1.RestrictedDomain
 */
export class RestrictedDomain extends Message<RestrictedDomain> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: string domain = 2;
   */
  domain = "";

  constructor(data?: PartialMessage<RestrictedDomain>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.RestrictedDomain";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): RestrictedDomain {
    return new RestrictedDomain().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): RestrictedDomain {
    return new RestrictedDomain().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): RestrictedDomain {
    return new RestrictedDomain().fromJsonString(jsonString, options);
  }

  static equals(a: RestrictedDomain | PlainMessage<RestrictedDomain> | undefined, b: RestrictedDomain | PlainMessage<RestrictedDomain> | undefined): boolean {
    return proto3.util.equals(RestrictedDomain, a, b);
  }
}

/**
 * Firestore /domains/:id
 *
 * @generated from message perkup.v1.Domain
 */
export class Domain extends Message<Domain> {
  /**
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 2;
   */
  created?: Timestamp;

  /**
   * @generated from field: string domain = 3;
   */
  domain = "";

  /**
   * @generated from field: string org_id = 4;
   */
  orgId = "";

  /**
   * @generated from field: bool verified = 5;
   */
  verified = false;

  constructor(data?: PartialMessage<Domain>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.Domain";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "created", kind: "message", T: Timestamp },
    { no: 3, name: "domain", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 4, name: "org_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "verified", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): Domain {
    return new Domain().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): Domain {
    return new Domain().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): Domain {
    return new Domain().fromJsonString(jsonString, options);
  }

  static equals(a: Domain | PlainMessage<Domain> | undefined, b: Domain | PlainMessage<Domain> | undefined): boolean {
    return proto3.util.equals(Domain, a, b);
  }
}

/**
 * Firestore /organizations/:id/transactions/:id
 *
 * @generated from message perkup.v1.OrgTransaction
 */
export class OrgTransaction extends Message<OrgTransaction> {
  /**
   * stored in firestore
   *
   * @generated from field: string id = 1;
   */
  id = "";

  /**
   * @generated from field: int32 amount = 2;
   */
  amount = 0;

  /**
   * @generated from field: bool approved = 3;
   */
  approved = false;

  /**
   * @generated from field: string auth_id = 4;
   */
  authId = "";

  /**
   * @generated from field: string category = 5;
   */
  category = "";

  /**
   * @generated from field: google.protobuf.Timestamp created = 6;
   */
  created?: Timestamp;

  /**
   * @generated from field: string currency = 7;
   */
  currency = "";

  /**
   * @generated from field: optional string declined_reason = 8;
   */
  declinedReason?: string;

  /**
   * @generated from field: string description = 9;
   */
  description = "";

  /**
   * @generated from field: optional string description_clean = 10;
   */
  descriptionClean?: string;

  /**
   * @generated from field: optional bool is_gift = 11;
   */
  isGift?: boolean;

  /**
   * HeronMerchant
   *
   * @generated from field: optional perkup.v1.OrgTransaction.OrgTxnMerchant merchant = 12;
   */
  merchant?: OrgTransaction_OrgTxnMerchant;

  /**
   * @generated from field: string status = 13;
   */
  status = "";

  /**
   * @generated from field: string type = 14;
   */
  type = "";

  /**
   * @generated from field: string user_id = 15;
   */
  userId = "";

  /**
   * @generated from field: optional perkup.v1.OrgTransaction.Shipping shipping = 16;
   */
  shipping?: OrgTransaction_Shipping;

  /**
   * @generated from field: optional perkup.v1.OrgTransaction.DigitalOrderInfo digital_order_info = 17;
   */
  digitalOrderInfo?: OrgTransaction_DigitalOrderInfo;

  /**
   * @generated from field: optional perkup.v1.ProductVariant.SourceType item_source_type = 19;
   */
  itemSourceType?: ProductVariant_SourceType;

  constructor(data?: PartialMessage<OrgTransaction>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrgTransaction";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "amount", kind: "scalar", T: 5 /* ScalarType.INT32 */ },
    { no: 3, name: "approved", kind: "scalar", T: 8 /* ScalarType.BOOL */ },
    { no: 4, name: "auth_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "category", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "created", kind: "message", T: Timestamp },
    { no: 7, name: "currency", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "declined_reason", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 9, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 10, name: "description_clean", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 11, name: "is_gift", kind: "scalar", T: 8 /* ScalarType.BOOL */, opt: true },
    { no: 12, name: "merchant", kind: "message", T: OrgTransaction_OrgTxnMerchant, opt: true },
    { no: 13, name: "status", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 14, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 15, name: "user_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 16, name: "shipping", kind: "message", T: OrgTransaction_Shipping, opt: true },
    { no: 17, name: "digital_order_info", kind: "message", T: OrgTransaction_DigitalOrderInfo, opt: true },
    { no: 19, name: "item_source_type", kind: "enum", T: proto3.getEnumType(ProductVariant_SourceType), opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgTransaction {
    return new OrgTransaction().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgTransaction {
    return new OrgTransaction().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgTransaction {
    return new OrgTransaction().fromJsonString(jsonString, options);
  }

  static equals(a: OrgTransaction | PlainMessage<OrgTransaction> | undefined, b: OrgTransaction | PlainMessage<OrgTransaction> | undefined): boolean {
    return proto3.util.equals(OrgTransaction, a, b);
  }
}

/**
 * @generated from message perkup.v1.OrgTransaction.OrgTxnMerchant
 */
export class OrgTransaction_OrgTxnMerchant extends Message<OrgTransaction_OrgTxnMerchant> {
  /**
   * @generated from field: repeated perkup.v1.OrgTransaction.Category categories = 2;
   */
  categories: OrgTransaction_Category[] = [];

  /**
   * @generated from field: float confidence = 3;
   */
  confidence = 0;

  /**
   * @generated from field: string heron_id = 4 [json_name = "heron_id"];
   */
  heronId = "";

  /**
   * @generated from field: string icon_url = 5 [json_name = "icon_url"];
   */
  iconUrl = "";

  /**
   * @generated from field: string logo_url = 6 [json_name = "logo_url"];
   */
  logoUrl = "";

  /**
   * @generated from field: string name = 7;
   */
  name = "";

  /**
   * @generated from field: string url = 8;
   */
  url = "";

  constructor(data?: PartialMessage<OrgTransaction_OrgTxnMerchant>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrgTransaction.OrgTxnMerchant";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 2, name: "categories", kind: "message", T: OrgTransaction_Category, repeated: true },
    { no: 3, name: "confidence", kind: "scalar", T: 2 /* ScalarType.FLOAT */ },
    { no: 4, name: "heron_id", jsonName: "heron_id", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 5, name: "icon_url", jsonName: "icon_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 6, name: "logo_url", jsonName: "logo_url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 7, name: "name", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 8, name: "url", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgTransaction_OrgTxnMerchant {
    return new OrgTransaction_OrgTxnMerchant().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgTransaction_OrgTxnMerchant {
    return new OrgTransaction_OrgTxnMerchant().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgTransaction_OrgTxnMerchant {
    return new OrgTransaction_OrgTxnMerchant().fromJsonString(jsonString, options);
  }

  static equals(a: OrgTransaction_OrgTxnMerchant | PlainMessage<OrgTransaction_OrgTxnMerchant> | undefined, b: OrgTransaction_OrgTxnMerchant | PlainMessage<OrgTransaction_OrgTxnMerchant> | undefined): boolean {
    return proto3.util.equals(OrgTransaction_OrgTxnMerchant, a, b);
  }
}

/**
 * @generated from message perkup.v1.OrgTransaction.Category
 */
export class OrgTransaction_Category extends Message<OrgTransaction_Category> {
  /**
   * @generated from field: string code = 1;
   */
  code = "";

  /**
   * @generated from field: string description = 2;
   */
  description = "";

  /**
   * @generated from field: string slug = 3;
   */
  slug = "";

  constructor(data?: PartialMessage<OrgTransaction_Category>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrgTransaction.Category";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "code", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "description", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "slug", kind: "scalar", T: 9 /* ScalarType.STRING */ },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgTransaction_Category {
    return new OrgTransaction_Category().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgTransaction_Category {
    return new OrgTransaction_Category().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgTransaction_Category {
    return new OrgTransaction_Category().fromJsonString(jsonString, options);
  }

  static equals(a: OrgTransaction_Category | PlainMessage<OrgTransaction_Category> | undefined, b: OrgTransaction_Category | PlainMessage<OrgTransaction_Category> | undefined): boolean {
    return proto3.util.equals(OrgTransaction_Category, a, b);
  }
}

/**
 * @generated from message perkup.v1.OrgTransaction.Shipping
 */
export class OrgTransaction_Shipping extends Message<OrgTransaction_Shipping> {
  /**
   * @generated from field: string carrier = 1;
   */
  carrier = "";

  /**
   * @generated from field: string tracking_number = 2;
   */
  trackingNumber = "";

  /**
   * @generated from field: optional string tracking_url = 3;
   */
  trackingUrl?: string;

  /**
   * @generated from field: optional perkup.v1.ShipmentStatus.Enum shipment_status = 4;
   */
  shipmentStatus?: ShipmentStatus_Enum;

  /**
   * @generated from field: optional perkup.v1.OrderFulfillment.FulfillmentStatus.Enum fulfillment_status = 5;
   */
  fulfillmentStatus?: OrderFulfillment_FulfillmentStatus_Enum;

  /**
   * @generated from field: optional google.protobuf.Timestamp shipment_date = 6;
   */
  shipmentDate?: Timestamp;

  /**
   * @generated from field: optional google.protobuf.Timestamp delivery_date = 7;
   */
  deliveryDate?: Timestamp;

  constructor(data?: PartialMessage<OrgTransaction_Shipping>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrgTransaction.Shipping";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "carrier", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 2, name: "tracking_number", kind: "scalar", T: 9 /* ScalarType.STRING */ },
    { no: 3, name: "tracking_url", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "shipment_status", kind: "enum", T: proto3.getEnumType(ShipmentStatus_Enum), opt: true },
    { no: 5, name: "fulfillment_status", kind: "enum", T: proto3.getEnumType(OrderFulfillment_FulfillmentStatus_Enum), opt: true },
    { no: 6, name: "shipment_date", kind: "message", T: Timestamp, opt: true },
    { no: 7, name: "delivery_date", kind: "message", T: Timestamp, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgTransaction_Shipping {
    return new OrgTransaction_Shipping().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgTransaction_Shipping {
    return new OrgTransaction_Shipping().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgTransaction_Shipping {
    return new OrgTransaction_Shipping().fromJsonString(jsonString, options);
  }

  static equals(a: OrgTransaction_Shipping | PlainMessage<OrgTransaction_Shipping> | undefined, b: OrgTransaction_Shipping | PlainMessage<OrgTransaction_Shipping> | undefined): boolean {
    return proto3.util.equals(OrgTransaction_Shipping, a, b);
  }
}

/**
 * @generated from message perkup.v1.OrgTransaction.DigitalOrderInfo
 */
export class OrgTransaction_DigitalOrderInfo extends Message<OrgTransaction_DigitalOrderInfo> {
  /**
   * @generated from field: optional string redeem_link_or_code = 1;
   */
  redeemLinkOrCode?: string;

  /**
   * @generated from field: optional string redeem_pin = 2;
   */
  redeemPin?: string;

  /**
   * @generated from field: optional string delivery_status = 3;
   */
  deliveryStatus?: string;

  /**
   * @generated from field: optional string type = 4;
   */
  type?: string;

  /**
   * @generated from field: optional string validity = 5;
   */
  validity?: string;

  /**
   * @generated from field: optional string redemption_instructions = 6;
   */
  redemptionInstructions?: string;

  constructor(data?: PartialMessage<OrgTransaction_DigitalOrderInfo>) {
    super();
    proto3.util.initPartial(data, this);
  }

  static readonly runtime: typeof proto3 = proto3;
  static readonly typeName = "perkup.v1.OrgTransaction.DigitalOrderInfo";
  static readonly fields: FieldList = proto3.util.newFieldList(() => [
    { no: 1, name: "redeem_link_or_code", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 2, name: "redeem_pin", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 3, name: "delivery_status", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 4, name: "type", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 5, name: "validity", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
    { no: 6, name: "redemption_instructions", kind: "scalar", T: 9 /* ScalarType.STRING */, opt: true },
  ]);

  static fromBinary(bytes: Uint8Array, options?: Partial<BinaryReadOptions>): OrgTransaction_DigitalOrderInfo {
    return new OrgTransaction_DigitalOrderInfo().fromBinary(bytes, options);
  }

  static fromJson(jsonValue: JsonValue, options?: Partial<JsonReadOptions>): OrgTransaction_DigitalOrderInfo {
    return new OrgTransaction_DigitalOrderInfo().fromJson(jsonValue, options);
  }

  static fromJsonString(jsonString: string, options?: Partial<JsonReadOptions>): OrgTransaction_DigitalOrderInfo {
    return new OrgTransaction_DigitalOrderInfo().fromJsonString(jsonString, options);
  }

  static equals(a: OrgTransaction_DigitalOrderInfo | PlainMessage<OrgTransaction_DigitalOrderInfo> | undefined, b: OrgTransaction_DigitalOrderInfo | PlainMessage<OrgTransaction_DigitalOrderInfo> | undefined): boolean {
    return proto3.util.equals(OrgTransaction_DigitalOrderInfo, a, b);
  }
}

