import { updateProgram } from 'api/databaseCalls'
import { PerkLoader, ThemeEditor } from 'components'
import { CustomCarousel } from 'components/Carousels/CustomCarousel'
import {
  ProgramTemplateCarouselItems,
  ProgramTemplateSkeleton,
} from 'components/Carousels/ProgramTemplatesCarousel'
import {
  ApprovedCategories,
  allApprovedCats,
} from 'constants/EligibleCategories'
import { PROGRAM_STEP_COMPLETED } from 'constants/events'
import { RewardSteps } from 'constants/rewards'
import { DEFAULT_ROUTES } from 'constants/routes'
import {
  OrgContext,
  ProgramContext,
  RewardStepGuideContext,
  TemplateContext,
} from 'context'
import { Heading, Pane } from 'evergreen-ui'
import { PartialWithFieldValue } from 'firebase/firestore'
import { ProgramTemplate } from 'gen/perkup/v1/contentful_pb'
import { Program, Program_DraftDataField } from 'gen/perkup/v1/program_pb'
import useIds from 'hooks/useIds'
import { useRecentlySentPrograms } from 'hooks/useRecentlySentPrograms'
import { uniqBy } from 'lodash-es'
import { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { ProgramTheme } from 'types'
import { logEvent } from 'utils'
import {
  isFoodProgramTemplate,
  programsToTemplates,
} from './utils/program-templates'
import { parseDraftData } from './utils/programs'

export function RewardImage() {
  const program = useContext(ProgramContext)
  const template = useContext(TemplateContext)
  const navigate = useNavigate()
  const org = useContext(OrgContext)
  const { steps, currentStepIndex } = useContext(RewardStepGuideContext)
  const { orgId, userId } = useIds()
  const { programs, isLoading: isLoadingPrograms } = useRecentlySentPrograms()
  const [isLoadingSave, setIsLoadingSave] = useState(false)

  const emailWritable = !!template?.email?.writable

  const recentProgramTemplates: ProgramTemplate[] = useMemo(() => {
    return programsToTemplates({
      programs: uniqBy(programs, p => p?.email?.banner),
    })
  }, [programs])

  const stepCompletedInfo = {
    step: RewardSteps.IMAGE,
    orgId,
    userId,
    programId: program.id,
  }

  const nextStep = steps[currentStepIndex + 1]?.path

  const handleSelectTemplate = async ({
    newTemplate,
    useTemplateCopy,
    draftData,
  }: {
    newTemplate: ProgramTemplate
    useTemplateCopy: boolean
    draftData?: string
  }) => {
    setIsLoadingSave(true)

    const isFoodTemplate = isFoodProgramTemplate(newTemplate)
    const approvedCategories = isFoodTemplate
      ? [ApprovedCategories.FOOD]
      : allApprovedCats

    const title = emailWritable ? newTemplate.programTitle : undefined
    const note = emailWritable ? newTemplate.programMessage : undefined

    let programUpdates: PartialWithFieldValue<Program> = {
      email: {
        banner: newTemplate.programImage,
      },
    }

    if (useTemplateCopy) {
      programUpdates = {
        email: {
          banner: newTemplate.programImage,
          title,
          fromName: org.name,
        },
        approvedCategories,
        name: title,
        note,
        draftData,
      }
    }

    await updateProgram({
      orgId,
      programId: program.id,
      program: programUpdates,
      merge: true,
    })

    logEvent(PROGRAM_STEP_COMPLETED, {
      ...stepCompletedInfo,
    })
    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${nextStep}`
    )
    setIsLoadingSave(false)
  }

  const handleSelectTheme = async ({
    theme,
    advance,
    rewardUseCase,
  }: {
    theme: ProgramTheme
    advance?: boolean
    rewardUseCase?: string
  }) => {
    const programDraftData = parseDraftData({ program })
    const newDraftData = new Program_DraftDataField({
      ...programDraftData,
      useCase: rewardUseCase,
    }).toJsonString()

    if (advance) setIsLoadingSave(true)
    const hasTemplateData =
      theme.note && theme.title && theme.categories && theme.banner

    const title = emailWritable ? theme.title : undefined
    const note = emailWritable ? theme.note : undefined

    if (hasTemplateData) {
      const constructedTemplate = new ProgramTemplate({
        programImage: theme.banner,
        programTitle: title,
        programMessage: note,
        categories: theme.categories,
      })
      await handleSelectTemplate({
        newTemplate: constructedTemplate,
        useTemplateCopy: true,
        draftData: newDraftData,
      })
    } else {
      await updateProgram({
        orgId,
        programId: program.id,
        program: {
          email: {
            title,
            banner: theme.banner,
          },
          note,
          name: title,
          draftData: newDraftData,
        },

        merge: true,
      })
    }
    if (advance) {
      logEvent(PROGRAM_STEP_COMPLETED, {
        ...stepCompletedInfo,
      })

      navigate(
        `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${nextStep}`
      )
    }
    setIsLoadingSave(false)
  }

  const templateCarouselItems = ProgramTemplateCarouselItems({
    programTemplates: recentProgramTemplates,
    onTemplateSelect: ({ chosenTemplate }) =>
      handleSelectTemplate({
        newTemplate: chosenTemplate,
        useTemplateCopy: false,
      }),
  })

  if (isLoadingSave) return <PerkLoader />

  return (
    <Pane display="flex" flexDirection="column" width="100%" gap={32}>
      <Heading alignSelf="center" size={800}>
        Pick an image
      </Heading>
      <Pane
        width="100%"
        alignSelf="start"
        display="flex"
        flexDirection="column"
        gap={16}
      >
        {isLoadingPrograms && <ProgramTemplateSkeleton />}
        {!isLoadingPrograms && templateCarouselItems.length > 0 && (
          <CustomCarousel header="Recently used">
            {templateCarouselItems}
          </CustomCarousel>
        )}
      </Pane>
      <ThemeEditor
        programId={program.id}
        occasion={program.occasion}
        onSelectTheme={({ advance, emailData, rewardUseCase }) =>
          handleSelectTheme({ theme: emailData, advance, rewardUseCase })
        }
      />
    </Pane>
  )
}
