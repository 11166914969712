import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { Flex, Skeleton } from 'antd'
import {
  PerkEmpty,
  ProductCollectionCard,
  ProductCollectionsGrid,
} from 'components'
import { AsyncButton } from 'components/Buttons/AsyncButton'
import { Heading, Text } from 'evergreen-ui'
import {
  ProductCollection,
  ProductCollection_Permission,
} from 'gen/perkup/v1/product_collections_pb'
import { useAllowedCollections } from 'hooks'
import { PropsWithChildren, ReactNode } from 'react'
import { makePlural } from 'utils'

function BrowseProductCollectionsSelectable({
  currentProductCollectionId,
  onCollectionCardClick,
  onCollectionSelect,
  onCollectionRemoveClick,
  firstRowSuffix,
}: {
  currentProductCollectionId: string | undefined
  onCollectionCardClick: (productCollection: ProductCollection) => void
  onCollectionSelect: (productCollection: ProductCollection) => void
  onCollectionRemoveClick: () => void
  firstRowSuffix?: ReactNode
}) {
  const { isLoading, allowedCollections } = useAllowedCollections({
    minPermission: ProductCollection_Permission.send,
  })

  const collectionsToRender = allowedCollections.filter(
    collection => collection.productsCount > 0
  )

  if (isLoading) {
    return (
      <Flex vertical>
        {Array.from({ length: 3 }).map((_, index) => (
          <Skeleton
            key={`${index.toString()}`}
            paragraph={{ rows: 2 }}
            style={{ margin: 'auto', padding: 32 }}
            active
          />
        ))}
      </Flex>
    )
  }

  if (collectionsToRender.length === 0) {
    return (
      <PerkEmpty
        header="No product collections found"
        description={"We couldn't find exactly what you were looking for"}
      />
    )
  }

  return (
    <Flex vertical style={{ width: '100%' }}>
      <Flex align="center" style={{ padding: '16px 0px' }}>
        <Flex flex={1}>
          <Heading size={700}>Select a collection</Heading>
        </Flex>
        <Text color="muted" minWidth="fit-content" height="fit-content">
          {makePlural('collection', collectionsToRender.length, true)}
        </Text>
        {firstRowSuffix}
      </Flex>

      <ProductCollectionsGrid>
        {collectionsToRender.map(collection => {
          const isSelected = currentProductCollectionId === collection.id
          return (
            <ProductCollectionCard
              key={collection.id}
              isSelected={isSelected}
              productCollection={collection}
              onClick={() => onCollectionCardClick(collection)}
            >
              <ProductCollectionCard.Details />
              <ProductCollectionCard.CtaRow
                cta={
                  isSelected ? (
                    <AsyncButton
                      onClick={e => {
                        e.stopPropagation()
                        onCollectionRemoveClick()
                      }}
                      size="small"
                      icon={<MinusOutlined />}
                    >
                      Remove
                    </AsyncButton>
                  ) : (
                    <AsyncButton
                      onClick={e => {
                        e.stopPropagation()
                        onCollectionSelect(collection)
                      }}
                      size="small"
                      icon={<PlusOutlined />}
                    >
                      Select
                    </AsyncButton>
                  )
                }
              />
            </ProductCollectionCard>
          )
        })}
      </ProductCollectionsGrid>
    </Flex>
  )
}

function BrowseProductCollections({ children }: PropsWithChildren<{}>) {
  return children
}

BrowseProductCollections.Selectable = BrowseProductCollectionsSelectable

export default BrowseProductCollections
