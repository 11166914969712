import { useCubeQuery } from '@cubejs-client/react'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'

import { Label, PolarRadiusAxis, RadialBar, RadialBarChart } from 'recharts'

import type { Query } from '@cubejs-client/core'
import { ChartConfig, ChartContainer } from '@repo/shadcn/es/chart'
import { Skeleton } from 'antd'
import { Heading, Strong, Text } from 'evergreen-ui'
import { isEqual, round } from 'lodash-es'
import {
  CSSProperties,
  ComponentProps,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { makePlural } from 'utils'
import { useInsightsContext } from './insights-context'

function useDeepCompareDeps<T>(...values: T[]) {
  const ref = useRef<T[]>(values)

  if (!isEqual(ref.current, values)) {
    ref.current = values
  }

  return ref.current
}

function useIntervalBoolean({ ms = 1200, active = true } = {}) {
  const [value, setValue] = useState(false)

  useEffect(() => {
    if (!active) {
      return () => {}
    }
    const timer = setInterval(() => {
      setValue(v => !v)
    }, ms)

    return () => {
      clearInterval(timer)
    }
  }, [ms, active])

  return value
}

function useChartData({
  rate,
  isLoading,
}: {
  rate: number
  isLoading?: boolean
}) {
  const toggleChart = useIntervalBoolean({
    active: isLoading,
  })

  if (!isLoading) {
    const value = round(rate * 100, 2) * 100
    const chartConfig = {
      activeValue: {
        label: 'activeValue',
        color: 'var(--acceptance-chart-active)',
      },
      notActiveValue: {
        label: 'notActiveValue',
        color: 'var(--acceptance-chart-inactive)',
      },
    } satisfies ChartConfig

    return {
      chartData: [{ notActiveValue: 10000 - value, activeValue: value }],
      chartConfig,
      label: `${Math.round(value / 100).toLocaleString()}%`,
    }
  }

  const chartConfig = {
    activeValue: {
      label: 'activeValue',
      color: 'var(--acceptance-chart-active)',
    },
    notActiveValue: {
      label: 'notActiveValue',
      color: 'var(--acceptance-chart-inactive)',
    },
  } satisfies ChartConfig

  return {
    chartData: toggleChart
      ? [{ activeValue: 1260, notActiveValue: 1 }]
      : [{ activeValue: 1, notActiveValue: 1260 }],
    chartConfig,
    label: '',
  }
}

function AcceptanceRateChartComponent(
  props: Parameters<typeof useChartData>[0]
) {
  const { label, chartData, chartConfig } = useChartData(props)

  const labelContent: ComponentProps<typeof Label>['content'] = ({
    viewBox,
  }) => {
    if (viewBox && 'cx' in viewBox && 'cy' in viewBox) {
      return (
        <text x={viewBox.cx} y={viewBox.cy} textAnchor="middle">
          <tspan
            x={viewBox.cx}
            y={(viewBox.cy || 0) - 16}
            className="fill-foreground text-2xl font-semibold"
          >
            {label}
          </tspan>
        </text>
      )
    }
    return null
  }

  return (
    <ChartContainer config={chartConfig} className="w-52 h-32 m-0 p-0">
      <RadialBarChart
        data={chartData}
        endAngle={180}
        innerRadius={85}
        outerRadius={140}
        cy="80%"
      >
        <PolarRadiusAxis tick={false} tickLine={false} axisLine={false}>
          <Label content={labelContent} />
        </PolarRadiusAxis>
        <RadialBar
          dataKey="notActiveValue"
          fill="var(--color-notActiveValue)"
          stackId="a"
          cornerRadius={5}
          className="stroke-transparent stroke-2"
        />
        <RadialBar
          dataKey="activeValue"
          stackId="a"
          cornerRadius={5}
          fill="var(--color-activeValue)"
          className="stroke-transparent stroke-2"
        />
      </RadialBarChart>
    </ChartContainer>
  )
}

function AcceptanceRateChart({ filters = [] }: { filters?: Query['filters'] }) {
  const orgId = useDevSafeOrgId()

  const { dateRange, insightsGlobalFilters } = useInsightsContext()

  const { resultSet, isLoading, error } = useCubeQuery({
    limit: 5000,
    measures: ['acceptanceRate.rate'],
    filters: [
      {
        member: 'organizations.id',
        operator: 'equals',
        values: [orgId],
      },
      ...insightsGlobalFilters,
      ...filters,
    ],
    timeDimensions: [
      {
        dimension: 'acceptanceRate.created',
        dateRange,
      },
    ],
  })

  const getChartProps = () => {
    if (isLoading || !resultSet || error || !resultSet.rawData()[0])
      return { rate: 0, isLoading: true }

    return {
      rate: Number(resultSet.rawData()[0]['acceptanceRate.rate'] as string),
      isLoading: false,
    }
  }

  const chartProps = useMemo(() => {
    return getChartProps()
    // using deep compare to avoid unnecessary re-renders
    // re-renders may reset the animation
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, useDeepCompareDeps(getChartProps()))

  return <AcceptanceRateChartComponent {...chartProps} />
}

function MedianAcceptanceDaysSkeleton() {
  return (
    <div className="flex flex-col items-center h-11 justify-center">
      <Skeleton.Input size="small" active />
    </div>
  )
}

function MedianAcceptanceDays({
  filters = [],
}: {
  filters?: Query['filters']
}) {
  const orgId = useDevSafeOrgId()

  const { dateRange, insightsGlobalFilters } = useInsightsContext()

  const { resultSet, isLoading, error } = useCubeQuery({
    limit: 5000,
    measures: ['acceptanceRate.medianHoursToAccept'],
    timeDimensions: [
      {
        dimension: 'acceptanceRate.created',
        dateRange,
      },
    ],
    filters: [
      {
        member: 'acceptanceRate.hoursToAccept',
        operator: 'set',
      },
      {
        member: 'organizations.id',
        operator: 'equals',
        values: [orgId],
      },
      ...filters,
      ...insightsGlobalFilters,
    ],
  })

  if (isLoading || !resultSet || error || !resultSet.rawData()[0]) {
    return <MedianAcceptanceDaysSkeleton />
  }

  const medianHoursToAccept =
    resultSet.rawData()[0]['acceptanceRate.medianHoursToAccept']

  const medianHoursToAcceptComputed = Math.ceil(medianHoursToAccept as number)
  const medianDaysToAcceptComputed = medianHoursToAcceptComputed / 24

  return (
    <div className="flex flex-col items-center">
      <Strong>
        {medianDaysToAcceptComputed < 1
          ? makePlural('hour', medianHoursToAcceptComputed, true)
          : makePlural('day', Math.ceil(medianDaysToAcceptComputed), true)}
      </Strong>
      <Text size={300} color="muted" className="text-center">
        Median acceptance time
      </Text>
    </div>
  )
}

const SEGMENTED_CHATS: {
  id: string
  title: string
  filters: Query['filters']
}[] = [
  {
    id: 'swag',
    title: 'Swag',
    filters: [
      {
        member: 'acceptanceRate.programs_rewardType',
        operator: 'equals',
        values: ['swag'],
      },
    ],
  },
  {
    id: 'cash',
    title: 'PerkUp dollars',
    filters: [
      {
        member: 'acceptanceRate.programs_rewardType',
        operator: 'equals',
        values: ['nearCash'],
      },
    ],
  },
  {
    id: 'publicGift',
    title: 'Gifts',
    filters: [
      {
        member: 'acceptanceRate.programs_rewardType',
        operator: 'equals',
        values: ['publicGift'],
      },
    ],
  },
]

export function AcceptanceRate() {
  return (
    <section
      className="@container flex flex-col gap-6"
      style={
        {
          '--acceptance-chart-active': 'hsl(var(--chart-dark))',
          '--acceptance-chart-inactive': 'hsl(var(--chart-lighter))',
        } as CSSProperties
      }
    >
      <Heading>Acceptance rate</Heading>

      <section className="flex flex-row items-center gap-2 @lg:gap-8 @lg:flex-col">
        <div className="flex-1 flex flex-col items-center">
          <Text>Overall</Text>
          <div>
            <AcceptanceRateChart />
          </div>
          <div className="-mt-6">
            <MedianAcceptanceDays />
          </div>
        </div>

        <div
          className="flex flex-col justify-evenly @lg:flex-row"
          style={
            {
              '--acceptance-chart-active': 'hsl(var(--chart-base))',
              '--acceptance-chart-inactive': 'hsl(var(--chart-lighter))',
            } as CSSProperties
          }
        >
          {SEGMENTED_CHATS.map(({ id, title, filters }) => (
            <div key={id} className="flex flex-col items-center">
              <Text>{title}</Text>
              <div className="scale-75 -mt-2">
                <AcceptanceRateChart filters={filters} />
              </div>
              <div className="-mt-6">
                <MedianAcceptanceDays filters={filters} />
              </div>
            </div>
          ))}
        </div>
      </section>
    </section>
  )
}
