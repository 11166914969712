import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { Timestamp } from '@bufbuild/protobuf'
import { Button, Collapse, CollapseProps, Flex, Modal } from 'antd'
import { createOrgShippingAddress } from 'api/databaseCalls'
import {
  AccountBalanceForm,
  AddressesList,
  PerkActionFooter,
  PerkLoader,
  PerkScrollbars,
} from 'components'
import { ChangeAddressButton } from 'components/Addresses'
import { ApprovedCategories } from 'constants/EligibleCategories'
import { PROGRAM_STEP_COMPLETED } from 'constants/events'
import {
  FORM_INPUT_MAX_WIDTH,
  PAGE_MAX_WIDTH,
} from 'constants/newReward/layout'
import { RewardSteps } from 'constants/rewards'
import { DEFAULT_ROUTES, REVIEW } from 'constants/routes'
import { OrgContext, ProgramContext, TemplateContext } from 'context'
import dayjs, { Dayjs } from 'dayjs'
import { Heading, Pane, Strong, Text, toaster } from 'evergreen-ui'
import { RecipientsControlCenter, SelectReward } from 'features'
import { deleteField } from 'firebase/firestore'
import { ProductVariant_Type } from 'gen/perkup/v1/product_variant_pb'
import { Program_RewardTab } from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useOrgShippingAddresses } from 'hooks'
import useIds from 'hooks/useIds'
import { isEmpty, values } from 'lodash-es'
import { useContext, useMemo, useState } from 'react'
import { useNavigate } from 'react-router'
import { RangeValue } from 'types/RewardsDate'
import { logEvent } from 'utils'
import { CoreValuesTags } from './components/CoreValuesTags'
import EmailForm from './components/EmailForm'
import ExpiredForm from './components/ExpiredForm'
import FoodForm from './components/FoodForm'
import InternalMemoForm from './components/InternalMemoForm'
import RewardEmailPreview from './components/RewardEmailPreview'
import { useFormatDraftProgram } from './hooks/useFormatDraftProgram'
import { EmailFormData } from './types/forms'
import { getRewardMetadata } from './utils/program-gifts'
import { parseDraftData, stringifyDraftData } from './utils/programs'
import { generateRandomEmail } from './utils/uiUtils'

export function Recipients() {
  const program = useContext(ProgramContext)
  const template = useContext(TemplateContext)
  const { orgId, userId } = useIds()
  const { shippingAddresses } = useOrgShippingAddresses()

  const parsedData = parseDraftData({ program })

  const startDate = program?.startsOn
    ? dayjs(new Date(program?.startsOn.toDate()))
    : undefined
  const endDate = program?.endsOn
    ? dayjs(new Date(program?.endsOn.toDate()))
    : undefined

  const [expiryDate, setExpiryDate] = useState<Dayjs | undefined>(endDate)
  const [showAddressesDialog, setShowAddressesDialog] = useState(false)
  const [duration, setDuration] = useState<RangeValue>([
    startDate || null,
    endDate || null,
  ])

  const org = useContext(OrgContext)
  const navigate = useNavigate()

  const {
    isLoadingSave,
    recipientEmails,
    setRecipientEmails,
    recipientIndividualIds,
    setRecipientIndividualIds,
    handleProgramSave,
    hasLoadedDraftProgram,
    recipientFilters,
    setRecipientFilters,
    selectedAccount,
    setSelectedAccount,
  } = useFormatDraftProgram({ program })

  const stepCompletedInfo = {
    step: RewardSteps.PERSONALIZATION,
    orgId,
    userId,
    programId: program.id,
    ...program,
  }

  const handleContinue = () => {
    logEvent(PROGRAM_STEP_COMPLETED, {
      ...stepCompletedInfo,
    })
    navigate(
      `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD}/${program.id}${REVIEW}`
    )
  }
  const emailData: EmailFormData = useMemo(() => {
    const randomEmailData = generateRandomEmail(org.name)
    const note = program?.note || randomEmailData.note
    const title = program?.email?.title || randomEmailData.title
    const fromName = program?.email?.fromName || randomEmailData.fromName
    const banner = program?.email?.banner || randomEmailData.banner

    return { note, title, fromName, banner }
  }, [
    org.name,
    program?.email?.banner,
    program?.email?.fromName,
    program?.email?.title,
    program?.note,
  ])

  const showCoreValuesForm = !template || !!template?.coreValues?.writable
  const emailFormDisabled = template && !template?.email?.writable
  const showInternalMemoForm = !template

  // Allow predefining an address for swag and public gifts or if the program has a product collection, but not for gift cards
  const canPredefineAddress =
    program?.gift &&
    (program?.gift?.productVariantType !== ProductVariant_Type.nearCash ||
      !isEmpty(program?.gift?.productIds) ||
      !!program?.gift?.productCollectionId)

  const wantsFood =
    program.approvedCategories.length === 1 &&
    program.approvedCategories[0] === ApprovedCategories.FOOD

  const expiryDisabled = template && !template?.expiry?.writable

  const { isGiftsCatalog, isSwag } = getRewardMetadata(program)

  let selectRewardTab: Program_RewardTab = Program_RewardTab.perkupDollars
  if (isSwag) selectRewardTab = Program_RewardTab.swag
  if (isGiftsCatalog) selectRewardTab = Program_RewardTab.catalog

  if (!hasLoadedDraftProgram) return <PerkLoader />

  const handleChangeShippingAddress = async (address?: ShippingAddress) => {
    if (address && !address.id) {
      await createOrgShippingAddress({
        orgId,
        shippingAddress: address,
      })
    }
    handleProgramSave({
      predefinedAddress: address || deleteField(),
    })
  }

  const advancedOptions: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Advanced options',
      children: (
        <Flex vertical gap={16}>
          <Flex vertical>
            <Strong>Shipping address</Strong>
            <Text color="muted">
              Optional: Enter a shipping address for all orders. If left blank,
              recipients will provide their own.
            </Text>
          </Flex>
          {program?.predefinedAddress ? (
            <ChangeAddressButton
              selectedAddress={program.predefinedAddress}
              shippingAddresses={shippingAddresses}
              onAddressChange={handleChangeShippingAddress}
              showDeleteButton
            />
          ) : (
            <>
              <Button
                icon={<PlusOutlined />}
                type="default"
                style={{ width: 'fit-content' }}
                onClick={() => setShowAddressesDialog(true)}
              >
                Select address
              </Button>
              <Modal
                open={showAddressesDialog}
                onClose={() => setShowAddressesDialog(false)}
                title="Choose your address"
                footer={null}
                onCancel={() => setShowAddressesDialog(false)}
              >
                <PerkScrollbars style={{ height: 600 }}>
                  <AddressesList
                    onSelectAddress={address => {
                      handleChangeShippingAddress(address)
                      setShowAddressesDialog(false)
                    }}
                    shippingAddresses={shippingAddresses}
                  />
                </PerkScrollbars>
              </Modal>
            </>
          )}
        </Flex>
      ),
    },
  ]

  return (
    <Flex
      vertical
      style={{
        maxWidth: PAGE_MAX_WIDTH,
        margin: 'auto',
        width: '100%',
        marginBottom: 64,
      }}
    >
      <Heading size={800} marginBottom={16}>
        Recipients
      </Heading>
      <Flex gap={64}>
        <Flex vertical gap={32} flex={1}>
          <RecipientsControlCenter>
            <RecipientsControlCenter.Input
              selectedIds={recipientIndividualIds}
              selectedEmails={recipientEmails}
              selectedFilter={recipientFilters}
              onChange={({ selectedIds, selectedEmails, selectedFilter }) => {
                setRecipientIndividualIds(selectedIds)
                setRecipientEmails(selectedEmails)
                setRecipientFilters(selectedFilter)
                handleProgramSave({
                  draftData: stringifyDraftData({
                    ...parsedData,
                    individuals: selectedIds,
                    emails: selectedEmails,
                    recipientFilters: selectedFilter,
                    address: undefined,
                  }),
                })
              }}
            />
          </RecipientsControlCenter>

          <Pane marginY={16}>
            <EmailForm
              email={emailData}
              setEmail={emailData => {
                const { note, title, fromName } = emailData
                if (values(emailData).some(value => value.length === 0)) {
                  if (!title) {
                    toaster.warning('Title is required')
                  } else if (!fromName) {
                    toaster.warning('From name is required')
                  } else {
                    toaster.warning('Note is required')
                  }
                  return
                }

                handleProgramSave({
                  email: { ...emailData, fromName },
                  note,
                  name: title,
                })
              }}
              disabled={emailFormDisabled}
              program={program}
            />
          </Pane>

          {showCoreValuesForm && (
            <CoreValuesTags
              selectedCoreValues={program.coreValues}
              setSelectedCoreValues={value => {
                handleProgramSave({
                  coreValues: value,
                })
              }}
            />
          )}

          {!expiryDisabled &&
            (wantsFood ? (
              <FoodForm
                dateRange={duration}
                onRangeChange={value => {
                  setDuration(value)
                  const startProgramLater =
                    wantsFood && value && value[0]?.startOf('day')
                  const startDate = startProgramLater
                    ? Timestamp.fromDate(startProgramLater?.toDate())
                    : undefined
                  const endDate = value && value[1]?.endOf('day')
                  const expiryDate = endDate
                    ? Timestamp.fromDate(endDate?.toDate())
                    : undefined
                  if (!value) {
                    handleProgramSave({
                      startsOn: Timestamp.now(),
                      endsOn: deleteField(),
                    })
                  } else {
                    handleProgramSave({
                      startsOn: startDate,
                      endsOn: expiryDate,
                    })
                  }
                }}
              />
            ) : (
              <ExpiredForm
                expiryDate={expiryDate}
                setExpiryDate={value => {
                  setExpiryDate(value)

                  handleProgramSave({
                    endsOn: value
                      ? Timestamp.fromDate(value?.endOf('day').toDate())
                      : deleteField(),
                  })
                }}
              />
            ))}

          {showInternalMemoForm && (
            <InternalMemoForm
              internalMemo={program?.internalMemo}
              setInternalMemo={value => {
                handleProgramSave({
                  internalMemo: value,
                })
              }}
              maxWidth={FORM_INPUT_MAX_WIDTH}
            />
          )}
          {canPredefineAddress && <Collapse ghost items={advancedOptions} />}
        </Flex>

        <RewardEmailPreview email={emailData} isGift={!!program?.gift} />
      </Flex>
      <SelectReward.Context
        selectedReward={{
          gift: program.gift,
          budget: program.budget,
          approvedCategories: program.approvedCategories,
        }}
        onSelectReward={() => {}}
        key={`${selectRewardTab}`}
        initialTab={selectRewardTab}
      >
        <PerkActionFooter>
          <Flex gap={16} align="center">
            {isLoadingSave && (
              <Flex align="center" gap={16}>
                <Text color="muted">Saving</Text>
                <LoadingOutlined style={{ fontSize: 24 }} spin />
              </Flex>
            )}
            <SelectReward.CurrentSelectedRewardPopover readOnly />
            <SelectReward.SwagRedemptionPopover readOnly />
            {hasLoadedDraftProgram && (
              <AccountBalanceForm
                setSelectedAccount={value => {
                  setSelectedAccount(value)
                  if (!value) return
                  handleProgramSave({
                    accountId: value.id,
                  })
                }}
                selectedAccount={selectedAccount}
                size="large"
              />
            )}
            <Button
              size="large"
              type="primary"
              onClick={handleContinue}
              style={{ width: 248 }}
            >
              Continue
            </Button>
          </Flex>
        </PerkActionFooter>
      </SelectReward.Context>
    </Flex>
  )
}
