import { LeftOutlined, RightOutlined, StarOutlined } from '@ant-design/icons'
import { Button, Tag } from 'antd'
import { PerkImage, PerkScrollbars } from 'components'
import { WidthBreakpoints } from 'constants/layout'
import useEmblaCarousel from 'embla-carousel-react'
import { Pane } from 'evergreen-ui'
import { ProductVariant_Image } from 'gen/perkup/v1/product_variant_pb'
import {
  useDefaultOrgColors,
  usePrevNextButtons,
  useThumbnailButton,
} from 'hooks'
import { useState } from 'react'
import { isMobile } from 'react-device-detect'
import { EmblaStyles } from 'types/embla'

const emblaStyles: EmblaStyles = {
  embla: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    gap: 4,
  },
  viewport: {
    overflow: 'hidden',
  },
  container: {
    display: 'flex',
    marginLeft: '-16px',
  },
  slide: {
    display: 'flex',
    flex: '0 0 100%',
    paddingLeft: '16px',
    overflow: 'hidden',
  },
}

/**
 *
 * Used to display images in product details view.
 * Carousel logic/JSX can be extrapolated into a new compound component should we need to use it elsewhere in the future.
 */
export function ProductImages({
  productImages,
  startIndex = 0,
  isVertical = false,
  onConfirmThumbnail,
}: {
  productImages: ProductVariant_Image[]
  startIndex?: number
  isVertical?: boolean
  onConfirmThumbnail?: (thumbnail: ProductVariant_Image) => void
}) {
  const { defaultColor } = useDefaultOrgColors()

  const [isThumbnailButtonLoading, setIsThumbnailButtonLoading] =
    useState(false)

  const [emblaRef, emblaApi] = useEmblaCarousel({ startIndex })

  const { selectedIndex, onThumbnailButtonClick } = useThumbnailButton(emblaApi)

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi)

  const verticalLayout = isVertical || isMobile
  const showSetThumbnailButton = !!onConfirmThumbnail
  const isSelectedImageTheThumbnailImage = selectedIndex === 0

  return (
    <div
      style={{
        flex: 1,
        display: 'flex',
        gap: verticalLayout ? 16 : 4,
        flexDirection: verticalLayout ? 'column-reverse' : undefined,
        alignItems: verticalLayout ? 'center' : undefined,
      }}
    >
      {/** THUMBNAILS */}

      <PerkScrollbars
        style={{
          minWidth: 100,
          maxWidth: verticalLayout ? 320 : undefined,
          maxHeight: verticalLayout ? undefined : 480,
        }}
      >
        <section
          style={{
            display: 'flex',
            flexDirection: verticalLayout ? 'row' : 'column',
            gap: 16,
          }}
        >
          {productImages.slice(0, 20).map((image, index) => {
            return (
              <Pane
                key={image.id}
                cursor="pointer"
                onClick={() => onThumbnailButtonClick(index)}
              >
                <PerkImage
                  src={image.src}
                  alt={image.id}
                  sizes={`(max-width: ${WidthBreakpoints.XS}px) 64px,
              100px`}
                  style={{
                    aspectRatio: '1/1',
                    objectFit: 'cover',
                    width: isVertical ? 64 : 100,
                    height: isVertical ? 64 : 100,
                    borderRadius: 8,
                    border:
                      selectedIndex === index
                        ? `1px solid ${defaultColor}`
                        : undefined,
                  }}
                />
              </Pane>
            )
          })}
        </section>
      </PerkScrollbars>

      {/** MAIN CAROUSEL */}
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: 16,
          alignItems: 'center',
        }}
      >
        <div style={emblaStyles.embla}>
          {productImages.length > 1 && (
            <Button
              style={{ minWidth: 40 }}
              icon={<LeftOutlined />}
              type="text"
              size="large"
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
          )}
          <div style={emblaStyles.viewport} ref={emblaRef}>
            <div style={emblaStyles.container}>
              {productImages.map(image => (
                <div key={image.src} style={emblaStyles.slide}>
                  <PerkImage
                    preview
                    src={image.src}
                    sizes="100vw"
                    width="100%"
                    style={{
                      width: '100%',
                      aspectRatio: '1/1',
                      objectFit: 'contain',
                      borderRadius: 8,
                    }}
                  />
                </div>
              ))}
            </div>
          </div>
          {productImages.length > 1 && (
            <Button
              style={{ minWidth: 40 }}
              icon={<RightOutlined />}
              type="text"
              size="large"
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          )}
        </div>

        {showSetThumbnailButton && isSelectedImageTheThumbnailImage && (
          <Tag
            icon={<StarOutlined />}
            color="success"
            style={{
              fontSize: 14,
              height: 24,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            Thumbnail
          </Tag>
        )}
        {showSetThumbnailButton && !isSelectedImageTheThumbnailImage && (
          <Button
            size="small"
            loading={isThumbnailButtonLoading}
            disabled={isThumbnailButtonLoading}
            icon={<StarOutlined />}
            style={{ width: 'fit-content' }}
            onClick={() => {
              onConfirmThumbnail(productImages[selectedIndex])
              setIsThumbnailButtonLoading(true)
            }}
          >
            Set as thumbnail
          </Button>
        )}
      </section>
    </div>
  )
}
