import {
  DollarOutlined,
  MonitorOutlined,
  NumberOutlined,
} from '@ant-design/icons'
import { ResultSet } from '@cubejs-client/core'
import { useCubeQuery } from '@cubejs-client/react'
import {
  ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from '@repo/shadcn/es/chart'
import { Segmented, Skeleton } from 'antd'
import { PerkEmpty } from 'components'
import { Heading } from 'evergreen-ui'
import { useDevSafeOrgId } from 'hooks/useDevSafeOrgId'
import { round } from 'lodash-es'
import { useState } from 'react'
import { CartesianGrid, Line, LineChart, XAxis, YAxis } from 'recharts'
import { getDateTimeString, numToDollars } from 'utils'
import { determineGranularity } from 'utils/insights'
import { useInsightsContext } from './insights-context'

export function RewardsSentGraph({
  granularity,
  results,
}: {
  granularity: 'day' | 'month' | 'week'
  results: ResultSet<{
    'memberships.count': string | number | boolean | null
    'memberships.acceptedProgramsCount': string | number | boolean | null
    'memberships.programCentsBudgeted': string | number | boolean | null
    'memberships.acceptedProgramsSpent': string | number | boolean | null
  }>
}) {
  const [metricToDisplay, setMetricToDisplay] = useState<'#' | '$'>('#')

  const chartData = results.chartPivot().map(data => {
    const dateString = data.x

    let formattedTime = ''

    if (granularity === 'day') {
      formattedTime = getDateTimeString(new Date(dateString), {
        dateOnly: true,
        hideWeekday: true,
      }) // Jan 3, 2024
    }

    if (granularity === 'week') {
      formattedTime = `Week of ${getDateTimeString(new Date(dateString), {
        dateOnly: true,
        hideWeekday: true,
      })}` // Week of Jan 3, 2024
    }

    if (granularity === 'month') {
      const date = getDateTimeString(new Date(dateString), {
        dateOnly: true,
        hideWeekday: true,
        longDisplay: true,
      }).split(' ')
      formattedTime = `${date[0]} ${date[2]}` // January 2024
    }

    return {
      time: formattedTime,
      sent:
        metricToDisplay === '#'
          ? Number(data['memberships.count'] || 0)
          : round(Number(data['memberships.programCentsBudgeted'] || 0), -2), // These values are in cents, so round to the nearest hundred to get nearest dollar
      accepted:
        metricToDisplay === '#'
          ? Number(data['memberships.acceptedProgramsCount'] || 0)
          : round(Number(data['memberships.acceptedProgramsSpent'] || 0), -2), // These values are in cents, so round to the nearest hundred to get nearest dollar
    }
  })

  const totalSent = chartData.reduce((acc, { sent }) => acc + sent, 0)

  const totalAccepted = chartData.reduce(
    (acc, { accepted }) => acc + accepted,
    0
  )

  const largestSentValue = Math.max(...chartData.map(({ sent }) => sent))

  const calculateYAxisLeftMargin = () => {
    // Getting our value to the same number of digits
    const lengthOfLongestYValue =
      metricToDisplay === '#'
        ? largestSentValue.toLocaleString().length
        : (numToDollars(largestSentValue, 0) || '').length

    if (lengthOfLongestYValue > 6) return 8
    if (lengthOfLongestYValue > 5) return 4
    if (lengthOfLongestYValue > 4) return 0
    if (lengthOfLongestYValue > 3) return -4
    if (lengthOfLongestYValue > 2) return -14
    return -24
  }

  const chartConfig = {
    sent: {
      label: 'Sent',
      color: 'hsl(var(--chart-darker))',
    },
    accepted: {
      label: 'Accepted',
      color: 'hsl(var(--chart-base))',
    },
  } satisfies ChartConfig

  return (
    <div className="flex flex-col w-full rounded-lg border border-muted">
      <section className="flex items-center border-b border-b-muted">
        <div className="flex-1 flex justify-between p-4 lg:px-6">
          <Heading>Rewards</Heading>

          <Segmented
            className="print:hidden"
            options={[
              { value: '#', icon: <NumberOutlined /> },
              { value: '$', icon: <DollarOutlined /> },
            ]}
            value={metricToDisplay}
            onChange={setMetricToDisplay}
          />
        </div>

        <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
          <Heading>Sent</Heading>

          <div className="flex gap-2 items-center">
            <div className="w-3 h-3 bg-primary-foreground rounded-full" />
            <Heading size={700}>
              {
                metricToDisplay === '#'
                  ? totalSent.toLocaleString() // These values need to be formatted with a comma
                  : numToDollars(totalSent, 0) // These values are in cents, so this will display in dollar
              }
            </Heading>
          </div>
        </div>

        <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
          <Heading>Accepted</Heading>

          <div className="flex gap-2 items-center">
            <div className="w-3 h-3 bg-primary/70 rounded-full" />
            <Heading size={700}>
              {
                metricToDisplay === '#'
                  ? totalAccepted.toLocaleString() // These values need to be formatted with a comma
                  : numToDollars(totalAccepted, 0) // These values are in cents, so this will display in dollar
              }
            </Heading>
          </div>
        </div>
      </section>
      <ChartContainer className="max-h-44 p-4" config={chartConfig}>
        <LineChart
          accessibilityLayer
          data={chartData}
          margin={{
            left: calculateYAxisLeftMargin(),
            right: 12,
            top: 12,
          }}
        >
          <CartesianGrid vertical={false} />
          <XAxis
            dataKey="time"
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            tickFormatter={value => {
              // For day granularity, trim the string to just remove the year
              if (granularity === 'day') return value.split(',')[0] // Jan 3

              // For week granularity, trim the string to remove 'Week of' and the year
              if (granularity === 'week') {
                return value.replace('Week of ', '').split(',')[0] // Jan 3
              }

              // For month granularity, trim the string to just show the month
              return value.split(' ')[0] // January
            }}
          />
          <YAxis
            tickLine={false}
            axisLine={false}
            tickMargin={8}
            tickFormatter={value =>
              metricToDisplay === '#'
                ? value.toLocaleString()
                : numToDollars(value, 0)
            }
          />
          <ChartTooltip
            cursor={false}
            content={
              <ChartTooltipContent
                valueFormatter={value =>
                  metricToDisplay === '#'
                    ? value.toLocaleString()
                    : numToDollars(value, 0)
                }
              />
            }
          />

          <Line
            dataKey="sent"
            type="monotone"
            stroke="var(--color-sent)"
            strokeWidth={2}
            dot={false}
          />

          <Line
            dataKey="accepted"
            type="monotone"
            stroke="var(--color-accepted)"
            strokeWidth={2}
            dot={false}
          />
        </LineChart>
      </ChartContainer>
    </div>
  )
}

export function RewardsSent() {
  const orgId = useDevSafeOrgId()

  const { dateRange, dayJsDateRange, insightsGlobalFilters } =
    useInsightsContext()

  const granularity = determineGranularity(dayJsDateRange)

  const { resultSet, isLoading, error } = useCubeQuery({
    limit: 5000,
    measures: [
      'memberships.count',
      'memberships.acceptedProgramsCount',
      'memberships.programCentsBudgeted',
      'memberships.acceptedProgramsSpent',
    ],
    filters: [
      {
        member: 'organizations.id',
        operator: 'equals',
        values: [orgId],
      },
      {
        member: 'programs.status',
        operator: 'notEquals',
        values: ['draft'],
      },
      ...insightsGlobalFilters,
    ],
    timeDimensions: [
      {
        dimension: 'memberships.created',
        granularity,
        dateRange,
      },
    ],
  })

  if (isLoading) {
    return (
      <div className="flex flex-col w-full rounded-lg border border-muted">
        <section className="flex items-center border-b border-b-muted">
          <div className="flex-1 p-4 lg:px-6">
            <Heading>Rewards</Heading>
          </div>
          <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
            <Heading>Sent</Heading>

            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-primary-foreground rounded-full" />
              <Skeleton.Button active />
            </div>
          </div>
          <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
            <Heading>Accepted</Heading>

            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-primary/70 rounded-full" />
              <Skeleton.Button active />
            </div>
          </div>
        </section>

        <section className="p-4 lg:px-6">
          <Skeleton active paragraph={{ rows: 3 }} />
        </section>
      </div>
    )
  }

  if (error || !resultSet || !resultSet.chartPivot().length) {
    return (
      <div className="flex flex-col w-full rounded-lg border border-muted">
        <section className="flex items-center border-b border-b-muted">
          <div className="flex-1 p-4 lg:px-6">
            <Heading>Rewards</Heading>
          </div>
          <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
            <Heading>Sent</Heading>

            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-primary-foreground rounded-full" />
              <Heading size={700}>0</Heading>
            </div>
          </div>
          <div className="flex-1 flex justify-between items-center border-l p-4 lg:px-6">
            <Heading>Accepted</Heading>

            <div className="flex gap-2 items-center">
              <div className="w-3 h-3 bg-primary/70 rounded-full" />
              <Skeleton.Button active />
            </div>
          </div>
        </section>

        <section className="p-4 lg:px-6">
          <PerkEmpty
            iconNode={<MonitorOutlined />}
            header="No data found"
            description="Try a different date range or filter"
          />
        </section>
      </div>
    )
  }

  return <RewardsSentGraph granularity={granularity} results={resultSet} />
}
