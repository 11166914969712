import { Avatar as AntAvatar, Button, Flex, Progress, Tag, Tooltip } from 'antd'
import Skeleton from 'antd/es/skeleton/Skeleton'
import { deleteProgram } from 'api/databaseCalls'
import {
  BrandedTags,
  CreatedBy,
  DuplicateReward,
  ExpiredText,
  PerkImage,
  RewardLinkButton,
} from 'components'
import { DRAFT_PROGRAM_CLICKED } from 'constants/events'

import { DEFAULT_ROUTES } from 'constants/routes'
import { Avatar, Heading, Pane, Text, toaster, useTheme } from 'evergreen-ui'
import { AnimatePresence, motion } from 'framer-motion'
import { Program, ProgramStatus, ProgramType } from 'gen/perkup/v1/program_pb'
import { useIndividualRole, useProgramIndividuals } from 'hooks'
import useIds from 'hooks/useIds'
import isNumber from 'lodash-es/isNumber'
import { useState } from 'react'
import { Link } from 'react-router-dom'
import {
  buildFullName,
  getDateTimeString,
  getDraftProgramNavigationPath,
  logEvent,
  numToDollars,
} from 'utils'

function AvatarGroupSkeleton() {
  return (
    <AntAvatar.Group>
      <Skeleton.Avatar active shape="circle" />
      <Skeleton.Avatar active shape="circle" />
      <Skeleton.Avatar active shape="circle" />
    </AntAvatar.Group>
  )
}

function RecipientsThumbnail({
  programId,
  totalMembers,
  maxMembersToDisplay,
}: {
  programId: string
  totalMembers: number
  maxMembersToDisplay: number
}) {
  const { individuals, isLoading: isIndividualsLoading } =
    useProgramIndividuals({
      programId,
      limit: maxMembersToDisplay,
    })

  const showRemainingMemberAmount = totalMembers > maxMembersToDisplay
  const remainingMemberAmountString = `+${totalMembers - maxMembersToDisplay}`

  return (
    <Pane display="flex" gap={8} alignItems="center" marginLeft="auto">
      <Heading size={200}>Sent to </Heading>

      {isIndividualsLoading ? (
        <AvatarGroupSkeleton />
      ) : (
        <>
          {individuals.map(individual => {
            // Get initials
            const displayName =
              buildFullName({
                firstName: individual.firstName,
                lastName: individual.lastName,
              }) || individual.email

            // Render Avatar, icon takes priority over initials
            return (
              <Pane
                key={individual.id}
                id={individual.id}
                display="flex"
                alignItems="center"
              >
                <Tooltip title={displayName}>
                  <Avatar
                    src={individual.profilePicture}
                    name={displayName}
                    size={24}
                  />
                </Tooltip>
              </Pane>
            )
          })}

          {showRemainingMemberAmount && (
            <Tooltip title={remainingMemberAmountString}>
              <AntAvatar size={24}>{remainingMemberAmountString}</AntAvatar>
            </Tooltip>
          )}
        </>
      )}
    </Pane>
  )
}

export function ProgramCard({ program }: { program: Program }) {
  const {
    id: programId,
    name,
    budget,
    email,
    totalBudget,
    totalSpent,
    totalMembers,
    totalScheduleMembers,
    totalAccepted,
    type,
    ownerId,
    gift,
    created,
    internalMemo,
    status,
    coreValues,
    draftData,
    endsOn,
  } = program

  const [isHovered, setIsHovered] = useState(false)
  const { isAdmin, isManager } = useIndividualRole()
  const { orgId, userId } = useIds()
  const isPolicy = type === ProgramType.policies
  const theme = useTheme()

  const deleteDraftProgram = () => {
    deleteProgram({
      orgId,
      programId,
    })
      .then(() =>
        toaster.success('Successfully deleted draft', { id: 'delete-draft' })
      )
      .catch(() =>
        toaster.danger('Failed to delete draft, please contact support')
      )
  }

  const isGift = !!gift
  const isDraft = status === ProgramStatus.draft
  const giftImageUrl = gift?.imageUrl
  const remainingBudget = totalBudget - totalSpent
  const programEnded = !!endsOn && endsOn.toDate() < new Date()
  const showProgramEnded = programEnded && status === ProgramStatus.active

  let percentRemaining = Math.round((remainingBudget / totalBudget) * 100)
  if (isGift) {
    percentRemaining = Math.round(
      ((totalMembers - totalAccepted) / totalMembers) * 100
    )
  }

  // Both monetary and gift rewards use this - percent spent / accepted
  const percentRedeemed = Math.round(100 - percentRemaining)

  const managerSpotRewards =
    isManager && (type === ProgramType.rewards || type === ProgramType.direct)

  const policyTag = totalMembers === 1 ? 'person' : 'people'

  const isDirectMail = type === ProgramType.direct
  const showPeopleBadge =
    (managerSpotRewards || isAdmin) && !isDraft && !isDirectMail

  const rewardPath = isDraft
    ? getDraftProgramNavigationPath({ programId, draftData })
    : `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${programId}`

  const handleRewardCardClick = () => {
    if (status !== ProgramStatus.draft) return
    logEvent(DRAFT_PROGRAM_CLICKED, {
      programId,
      location: window.location.pathname,
      userId,
      orgId,
    })
  }

  const showProgramLinkButton =
    !isDraft &&
    status === ProgramStatus.active &&
    !isDirectMail &&
    type !== ProgramType.policies &&
    type !== ProgramType.perks

  return (
    <Pane
      is={Link}
      to={rewardPath}
      onClick={handleRewardCardClick}
      display="flex"
      alignItems="center"
      width="100%"
      borderRadius={8}
      hoverElevation={1}
      padding={16}
      gap={16}
      justifyContent="space-between"
      cursor="pointer"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Pane display="flex" alignItems="center" gap={16} flex={1}>
        <PerkImage
          src={giftImageUrl || email?.banner}
          sizes="256px" // Although the width is 84px square, our images are horizontal and are set to cover - so we need a larger image to avoid blur
          width={84}
          alt={name}
          style={{
            width: 84,
            objectFit: 'cover',
            aspectRatio: '1/1',
            borderRadius: 4,
          }}
        />
        <Pane display="flex" flexDirection="column" gap={8} width="100%">
          {/** PROGRAM INFO AND BUDGET METER */}
          <Pane display="flex" gap={16} alignItems="center">
            <Pane flex={1} display="flex" flexDirection="column" gap="0.5rem">
              <Pane>
                {internalMemo && <Heading size={100}>{internalMemo}</Heading>}
                <Pane display="flex" alignItems="center" gap={16} height={32}>
                  <Heading size={600} className="one-line-truncate">
                    {name}
                  </Heading>
                </Pane>
              </Pane>
              <Pane display="flex" alignItems="center" gap={16}>
                {!isGift && (
                  <Text>
                    {numToDollars(budget)}{' '}
                    {managerSpotRewards || isAdmin ? 'per person' : 'budget'}
                  </Text>
                )}
                {showPeopleBadge && (
                  <Tag color={isDraft ? 'orange' : 'green'}>
                    {totalMembers} {policyTag}
                  </Tag>
                )}
                {isDirectMail && !isDraft && (
                  <Tag color="orange">Direct mail</Tag>
                )}
                {(managerSpotRewards || isAdmin) &&
                  totalScheduleMembers > 0 && (
                    <Tag color="orange">{totalScheduleMembers} scheduled</Tag>
                  )}
              </Pane>
              <Pane display="flex" flexDirection="column" gap={4}>
                <Text size={300} color="muted">
                  <Pane display="flex" flexDirection="column">
                    {getDateTimeString(created?.toDate(), {
                      longDisplay: true,
                    })}
                  </Pane>
                </Text>
                {created && ownerId && (
                  <CreatedBy createdDate={created.toDate()}>
                    <CreatedBy.User userId={ownerId} />
                  </CreatedBy>
                )}
              </Pane>
              {showProgramEnded && <ExpiredText endsOn={endsOn.toDate()} />}
            </Pane>

            {totalBudget > 0 && !isDraft && (
              <Pane
                flex={2}
                display="flex"
                flexDirection="column"
                gap="0.25rem"
              >
                <Pane height={32}>
                  <AnimatePresence mode="wait">
                    {isHovered && !isDraft && (
                      <motion.div
                        key={`${programId}-duplicate-reward`}
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.25 }}
                      >
                        <Flex gap={8} justify="end">
                          {showProgramLinkButton && (
                            <RewardLinkButton programId={programId} />
                          )}
                          <DuplicateReward programId={programId} />
                        </Flex>
                      </motion.div>
                    )}
                    {/** Budget meter */}
                  </AnimatePresence>
                </Pane>
                <Progress
                  trailColor={theme.colors.green100}
                  strokeColor={theme.colors.green500}
                  percent={percentRedeemed}
                  strokeLinecap="round"
                  showInfo={false}
                />
                {/** Budget meter metrics */}
                <Pane
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  {!isGift && isNumber(percentRedeemed) && (
                    <Text color={theme.colors.green500}>
                      {percentRedeemed}% {isPolicy ? 'allocated' : ''}
                    </Text>
                  )}
                  {!isGift && (
                    <Text>
                      {numToDollars(totalSpent)} / {numToDollars(totalBudget)}{' '}
                      spent
                    </Text>
                  )}

                  {isGift && isNumber(percentRedeemed) && (
                    <Text color={theme.colors.green500}>
                      {percentRedeemed}%
                    </Text>
                  )}
                  {isGift && isNumber(totalAccepted) && (
                    <Text>
                      {totalAccepted} / {totalMembers}{' '}
                      {isDirectMail ? 'sent' : 'accepted'}
                    </Text>
                  )}
                </Pane>
                <Pane display="flex" marginTop={4}>
                  {coreValues && <BrandedTags coreValues={coreValues} />}

                  {!isDirectMail && totalMembers > 0 && (
                    <RecipientsThumbnail
                      programId={programId}
                      totalMembers={totalMembers}
                      maxMembersToDisplay={3}
                    />
                  )}
                </Pane>
              </Pane>
            )}
          </Pane>
        </Pane>
      </Pane>
      {/* Draft Buttons */}
      {isDraft && programId && (
        <Pane display="flex" gap={8}>
          <DuplicateReward useIconButton={false} programId={programId} />
          <Button
            danger
            onClick={e => {
              e.preventDefault()
              deleteDraftProgram()
            }}
          >
            Delete
          </Button>
        </Pane>
      )}
    </Pane>
  )
}
