import { Filter } from '@cubejs-client/core'
import { CubeProvider } from '@cubejs-client/react'
import { getDefaultDateRange } from 'constants/insights'
import { InsightsContext } from 'context'
import type { Dayjs } from 'dayjs'
import { groupBy } from 'lodash-es'
import { PropsWithChildren, useContext, useMemo, useState } from 'react'
import { getCubeApi } from 'services/cube'
import { dayJsDateRangeToApiDateRange } from 'utils/insights'

/**
 * DateRangeArray is a tuple with two strings representing the start and end dates
 *
 * in the format 'YYYY-MM-DD'
 * @example ['2021-01-01', '2021-01-31']
 */
export type DateRangeArray = [startDate: string, endDate: string]
type DateSource = 'chips' | 'date-picker' | 'default'

export type DayJsDateRange = [startDate: Dayjs, endDate: Dayjs]

type InsightsFilter = Filter & {
  /**
   * The source of the filter
   * this can be used by chart components to ignore filters that do not apply to them
   */
  getInsightsFilterSource: () => 'programOwnersLabels' | 'occasions'
}

interface InsightsGlobalFiltersRaw {
  programOwnersLabels: {
    key: string
    value: string
  }[]
  occasions: string[]
}

export interface InsightsContextType {
  /**
   * The date range to use for all queries in the context
   *
   * `undefined` means no date range is set
   */
  dateRange: DateRangeArray | undefined
  /**
   * The date range to use for all queries in the context
   *
   * `undefined` means no date range is set
   */
  dayJsDateRange: DayJsDateRange | undefined

  dateSource: DateSource

  updateDateRange: (
    dateRange: InsightsContextType['dayJsDateRange'],
    from: DateSource
  ) => void

  insightsGlobalFilters: InsightsFilter[]
  insightsGlobalFiltersRaw: InsightsGlobalFiltersRaw
  updateInsightsGlobalFilters: (filters: InsightsGlobalFiltersRaw) => void
}

export const useInsightsContext = () => {
  const context = useContext(InsightsContext)
  if (context === null) {
    throw new Error(
      'useInsightsContext must be used within a InsightsContextProvider'
    )
  }
  return context
}

export function InsightsContextProvider({
  children,
  authToken,
}: PropsWithChildren<{ authToken: string }>) {
  const [dayJsDateRange, setDayJsDateRage] = useState<
    InsightsContextType['dayJsDateRange']
  >(() => getDefaultDateRange())
  const [dateSource, setDateSource] = useState<DateSource>('default')
  const [insightsGlobalFiltersRaw, setInsightsGlobalFiltersRaw] =
    useState<InsightsGlobalFiltersRaw>({
      programOwnersLabels: [],
      occasions: [],
    })

  const state = useMemo<InsightsContextType>(
    () => ({
      dateRange: dayJsDateRangeToApiDateRange(dayJsDateRange),
      dayJsDateRange,
      insightsGlobalFiltersRaw,
      insightsGlobalFilters: [
        ...Object.entries(
          groupBy(insightsGlobalFiltersRaw.programOwnersLabels, 'key')
        ).flatMap(
          ([key, values]) =>
            [
              {
                member: 'programOwners.id',
                operator: 'set',
                getInsightsFilterSource: () => 'programOwnersLabels' as const,
              },
              {
                member: 'programOwnersLabels.labels',
                operator: 'contains',
                getInsightsFilterSource: () => 'programOwnersLabels' as const,
                values: values.map(({ value }) => `${key};;;${value}`),
              },
            ] as const
        ),
        ...(insightsGlobalFiltersRaw.occasions.length > 0
          ? ([
              {
                member: 'programOccasions.occasion',
                operator: 'set',
                getInsightsFilterSource: () => 'occasions' as const,
              },
              {
                member: 'programOccasions.occasion',
                operator: 'equals',
                getInsightsFilterSource: () => 'occasions' as const,
                values: insightsGlobalFiltersRaw.occasions,
              },
            ] as const)
          : []),
      ],
      dateSource,
      updateDateRange: (range, source) => {
        setDayJsDateRage(range)
        setDateSource(source)
      },
      updateInsightsGlobalFilters: setInsightsGlobalFiltersRaw,
    }),
    [dateSource, dayJsDateRange, insightsGlobalFiltersRaw]
  )
  const api = useMemo(() => getCubeApi(authToken), [authToken])

  return (
    <CubeProvider cubeApi={api}>
      <InsightsContext.Provider value={state}>
        {children}
      </InsightsContext.Provider>
    </CubeProvider>
  )
}
