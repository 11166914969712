import { SearchOutlined } from '@ant-design/icons'
import { deleteCart, updateCartItems } from 'api/databaseCalls/writes/carts'
import { SWAG_CARD } from 'assets/contentful'
import { PerkEmpty, PerkLoader } from 'components'
import { PRODUCT_VARIANT_ID, QUANITTY } from 'constants/params'
import { DEFAULT_ROUTES, HOME, SWAG } from 'constants/routes'
import { ProductVariantsCheckout } from 'features'
import { Cart, Cart_Item } from 'gen/perkup/v1/cart_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import useProductVariantsByIds from 'hooks/productVariants/useProductVariantsByIds'
import { isEmpty } from 'lodash-es'
import { useMemo } from 'react'
import {
  useNavigate,
  useOutletContext,
  useSearchParams,
} from 'react-router-dom'
import { WithSelectedQuantity } from 'types'
import { getProductVariantQuantitiesFromCart } from 'utils/productVariant'

export function SwagCheckoutPage() {
  const navigate = useNavigate()

  const [searchParams, setSearchParams] = useSearchParams()

  const { singleProductVariantId, quantity } = useMemo(() => {
    const singleProductVariantId = searchParams.get(PRODUCT_VARIANT_ID)
    const quantity = searchParams.get(QUANITTY)
    return { singleProductVariantId, quantity }
  }, [searchParams])

  const outletContext = useOutletContext() as {
    cart: Cart | undefined
    shippingAddress: ShippingAddress | undefined
  }

  const cart = outletContext?.cart
  const shippingAddress = outletContext?.shippingAddress

  const pvIds = useMemo(() => {
    // Means checking out single product with 'Buy now'
    if (singleProductVariantId) {
      return [singleProductVariantId]
    }
    if (cart) {
      return cart.lineItems.map(li => li.productVariantId)
    }
    return []
  }, [cart, singleProductVariantId])

  const { productVariants, isLoadingInitital } = useProductVariantsByIds({
    variantIds: pvIds,
  })

  if (isLoadingInitital) return <PerkLoader />

  if (!cart) {
    return (
      <PerkEmpty
        header={
          isEmpty(productVariants)
            ? 'No added products found'
            : 'Cart not found'
        }
        ctaProps={{
          children: 'Go home',
          onClick: () => navigate(`${HOME}`),
          type: 'primary',
        }}
        iconNode={<SearchOutlined style={{ fontSize: 42 }} />}
      />
    )
  }

  const handleAfterSubmit = () => {
    navigate(`${SWAG}`, {
      state: {
        imageUrl:
          productVariants.length === 1
            ? productVariants[0].imageUrl
            : SWAG_CARD,
        shippingAddress,
        transactionConfirmed: true,
      },
    })
    deleteCart({ cartId: cart.id })
  }

  const handleUpdateSelectedVariants = async (
    productVariants: WithSelectedQuantity<ProductVariant>[]
  ) => {
    if (!cart) return

    if (singleProductVariantId) {
      // User removed the only thing in their single product checkout....lol
      if (productVariants.length === 0) {
        navigate(DEFAULT_ROUTES.SWAG.ROOT)
        return
      }

      searchParams.set(QUANITTY, productVariants[0].selectedQuantity.toString())
      setSearchParams(searchParams)
      return
    }

    const updatedLineItems = productVariants.map(pv => {
      return new Cart_Item({
        productId: pv.productId,
        productVariantId: pv.id,
        quantity: pv.selectedQuantity,
        provider: pv.provider,
        type: pv.type,
      })
    })

    await updateCartItems({
      cartId: cart.id,
      lineItems: updatedLineItems,
    })
  }

  const productVariantsWithQuantities: WithSelectedQuantity<ProductVariant>[] =
    singleProductVariantId && quantity
      ? productVariants.map(pv =>
          Object.assign(pv, { selectedQuantity: Number(quantity) })
        )
      : getProductVariantQuantitiesFromCart(productVariants, cart)

  return (
    <ProductVariantsCheckout
      productVariants={productVariantsWithQuantities}
      onCheckoutComplete={handleAfterSubmit}
      onUpdateSelectedVariants={handleUpdateSelectedVariants}
    />
  )
}
