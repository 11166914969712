import { LoadingOutlined, LockFilled } from '@ant-design/icons'
import { ConnectError } from '@connectrpc/connect'
import { captureException, captureMessage } from '@sentry/react'
import { Elements, PaymentElement, useElements } from '@stripe/react-stripe-js'
import { PaymentIntent, Stripe, StripeElementsOptions } from '@stripe/stripe-js'
import { Button, Flex, Form, Image, Tag } from 'antd'
import { createNewIndividualsByEmails, updateProgram } from 'api/databaseCalls'
import { callFunction } from 'api/functionCalls'
import { PerkLoader } from 'components'
import { PERKUP_PRIMARY_LOGO } from 'constants/logos'
import { STRIPE_FEE_MULTIPLIER } from 'constants/money'
import { PAGE_MAX_WIDTH } from 'constants/newReward/layout'
import { SURVEY } from 'constants/params'
import { DEFAULT_ROUTES } from 'constants/routes'
import {
  IndividualContext,
  OrgContext,
  ProgramContext,
  TemplateContext,
  UserContext,
} from 'context'
import {
  EmptyState,
  Heading,
  Pane,
  SearchIcon,
  Strong,
  Text,
  toaster,
  useTheme,
} from 'evergreen-ui'
import { deleteField } from 'firebase/firestore'
import { ProductVariant_Provider } from 'gen/perkup/v1/product_variant_pb'
import {
  Item,
  Program,
  ProgramStatus,
  ProgramType,
} from 'gen/perkup/v1/program_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import {
  useAccount,
  useDefaultOrgColors,
  useShopifyCalcDraftOrder,
} from 'hooks'
import { useStripe } from 'hooks/Stripe/useStripe'
import useIds from 'hooks/useIds'
import { isEmpty } from 'lodash-es'
import { useContext, useEffect, useMemo, useRef, useState } from 'react'
import { useNavigate, useOutletContext } from 'react-router'
import { createSearchParams } from 'react-router-dom'
import { CreateTopUpInvoice, placeManualOrder } from 'services'
import {
  copyProgramAcceptanceLink,
  getFirstAndLastNameFromAddress,
  makePlural,
  numToDollars,
  toSentry,
} from 'utils'
import { getOrderErrorMessage } from 'utils/errors'
import {
  getOutOfStockProductVariants,
  getProductVariantOutOfStockMessage,
} from 'utils/productVariant'
import {
  getProgramEndsOnFromTemplateExpiry,
  getRewardQuantity,
  parseDraftData,
  removeSavedProgramImageQuery,
} from './utils/programs'

function calcRewardAmounts({
  programBudget = 0,
  recipientCount,
}: {
  programBudget: number | undefined
  recipientCount: number
}) {
  const subtotal = programBudget * recipientCount

  const feesAmount = subtotal * STRIPE_FEE_MULTIPLIER
  const totalAmount = subtotal + feesAmount

  // NOTE: totalAmount is just for display, BE expects pre-fee amount
  // When comparing acc balance to total, use subtotal. Fees don't get added to the account balance.
  return { subtotal, feesAmount, totalAmount }
}

function NewRewardCheckoutEmptyState({ stripe }: { stripe: Stripe | null }) {
  const theme = useTheme()
  const noStripeMessage =
    'Could not connect to Stripe. Please contact support or try again.'
  const noProgramMessage =
    'Could not find reward. Please contact support or try again'
  const title = !stripe ? noStripeMessage : noProgramMessage
  return (
    <EmptyState
      background="light"
      title={title}
      orientation="vertical"
      icon={<SearchIcon color={theme.colors.gray500} />}
      iconBgColor={theme.colors.gray200}
    />
  )
}

function PriceBreakdown({
  program,
  recipientCount,
  subtotal,
  feesAmount,
  totalAmount,
}: {
  program: Program
  recipientCount: number
  subtotal: number
  feesAmount: number
  totalAmount: number
}) {
  return (
    <Pane display="flex" flexDirection="column" border="muted" borderRadius={8}>
      <Pane
        display="flex"
        alignItems="center"
        borderBottom="muted"
        padding={16}
        gap={32}
      >
        <Image
          src={program?.email?.banner}
          style={{
            height: 80,
            width: 120,
            objectFit: 'contain',
          }}
        />

        <Pane display="flex" flexDirection="column">
          <Strong size={500}>{program?.name}</Strong>
          <Text>{numToDollars(program?.budget)} per person</Text>
        </Pane>

        <Tag color="green">
          {recipientCount} {makePlural('recipient', recipientCount)}
        </Tag>
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        gap={8}
        margin={16}
        paddingBottom={16}
        borderBottom="muted"
      >
        <Pane display="flex" justifyContent="space-between">
          <Text>Amount per recipient</Text>
          <Text>{numToDollars(program.budget)}</Text>
        </Pane>
        <Pane display="flex" justifyContent="space-between">
          <Text>{recipientCount} recipients</Text>
          <Text>x {recipientCount}</Text>
        </Pane>
      </Pane>
      <Pane
        display="flex"
        flexDirection="column"
        gap={8}
        margin={16}
        paddingBottom={16}
        borderBottom="muted"
      >
        <Pane display="flex" justifyContent="space-between">
          <Text>Subtotal</Text>
          <Text>{numToDollars(subtotal)}</Text>
        </Pane>
        <Pane display="flex" justifyContent="space-between">
          <Text>{STRIPE_FEE_MULTIPLIER * 100}% credit card fees</Text>
          <Text>{numToDollars(feesAmount)}</Text>
        </Pane>
      </Pane>
      <Pane
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        paddingTop={24}
        paddingBottom={32}
        paddingX={16}
      >
        <Strong>Total due</Strong>
        <Strong size={600}>{numToDollars(totalAmount, 2)}</Strong>
      </Pane>
    </Pane>
  )
}

function CheckoutContainer({
  clientSecret,
  stripe,
  program,
  recipientIds,
  recipientEmails,
}: {
  clientSecret: string
  stripe: Stripe
  program: Program
  recipientIds: string[]
  recipientEmails: string[]
}) {
  const org = useContext(OrgContext)
  const template = useContext(TemplateContext)
  const individual = useContext(IndividualContext)
  const user = useContext(UserContext)
  const { orgId, userId } = useIds()
  const orgName = org.name
  const navigate = useNavigate()
  const [isLoadingAccount, setIsLoadingAccount] = useState(false)
  const [productsOutOfStock, setProductsOutOfStock] = useState(false)
  const sendDirectMailGift = useRef(false)
  const {
    setIsSendingReward,
  }: {
    setIsSendingReward: (isSending: boolean) => void
    isSendingReward: boolean
  } = useOutletContext() // Tells outlet not to show empty state after we convert program from a draft but before we navigate to the program page
  const [isLoading, setIsLoading] = useState(false)

  const isDirectMail = program?.type === ProgramType.direct
  const recipientCount = getRewardQuantity({
    recipientCount: recipientEmails.length + recipientIds.length,
    isDirectMail,
  })
  const accountId = program?.accountId
  const { account } = useAccount({ accountId })
  const { defaultColor } = useDefaultOrgColors()
  const programBudget = program?.budget
  const { subtotal, feesAmount, totalAmount } = useMemo(
    () => calcRewardAmounts({ programBudget, recipientCount }),
    [programBudget, recipientCount]
  )

  const draftData = parseDraftData({
    program,
  })

  const recipientAddress: ShippingAddress | undefined = useMemo(() => {
    return new ShippingAddress(draftData?.address)
  }, [draftData?.address])

  const programItems = useMemo(() => {
    return program?.gift?.productVariantId
      ? [
          new Item({
            productVariantId: program?.gift?.productVariantId,
            quantity: 1,
            provider: ProductVariant_Provider.shopify,
          }),
        ]
      : undefined
  }, [program?.gift?.productVariantId])

  const { draftOrderCalculation } = useShopifyCalcDraftOrder({
    programItems,
    address: recipientAddress,
  })

  useEffect(() => {
    getOutOfStockProductVariants({ items: program.items }).then(variants => {
      if (!isEmpty(variants)) {
        setProductsOutOfStock(true)
        const errorMessage = getProductVariantOutOfStockMessage({ variants })
        toaster.warning('The following items have insufficient inventory:', {
          description: (
            <Text dangerouslySetInnerHTML={{ __html: errorMessage }} />
          ),
        })
      }
    })
  }, [program.items])

  // listen for account balance changes
  useEffect(() => {
    const programId = program?.id
    if (
      isDirectMail &&
      recipientAddress &&
      !isEmpty(program.items) &&
      account?.balance &&
      account.balance >= subtotal &&
      sendDirectMailGift.current === true
    ) {
      sendDirectMailGift.current = false
      const exceptionContexts = {
        handleSendDirectMailReward: {
          selectedProductVariantId: program?.gift?.productVariantId,
          user,
          senderIndividual: individual,
        },
      }

      const { firstName, lastName } =
        getFirstAndLastNameFromAddress(recipientAddress)

      placeManualOrder({
        firstName,
        lastName,
        shippingAddress: recipientAddress,
        items: program.items,
        programIds: [programId],
        shippingRateHandle:
          draftOrderCalculation?.shippingLine?.shippingRateHandle,
        orgId,
        userId,
      })
        .then(() => {
          toaster.success('Gift successfully accepted')

          sendDirectMailGift.current = false
          navigate(
            {
              pathname: `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${programId}`,
              search: createSearchParams({
                [SURVEY]: 'true',
              }).toString(),
            },
            {
              state: { confetti: true },
            }
          )
        })
        .catch((error: ConnectError) => {
          console.error(error)
          captureException(toSentry(error), {
            contexts: exceptionContexts,
          })

          const { title, description } = getOrderErrorMessage({
            error,
          })

          toaster.warning(title, {
            description: description ? (
              <Text dangerouslySetInnerHTML={{ __html: description }} />
            ) : null,
          })
        })
        .finally(() => {
          sendDirectMailGift.current = false
          setIsLoadingAccount(false)
        })
    }
  }, [
    account,
    isDirectMail,
    recipientAddress,
    program,
    orgId,
    userId,
    navigate,
    draftOrderCalculation,
    subtotal,
    individual,
    user,
    orgName,
  ])

  const elements = useElements()

  const handleSubmitProgram = async (paymentIntent: PaymentIntent) => {
    setIsSendingReward(true)
    removeSavedProgramImageQuery({ programId: program.id })

    if (paymentIntent.status === 'succeeded') {
      const programId = program.id

      const newIndividualIds = await createNewIndividualsByEmails({
        orgId,
        emails: recipientEmails,
      })

      const selectedIds = isDirectMail
        ? [individual.id]
        : [...recipientIds, ...newIndividualIds]

      callFunction('firestore-AddPeopleIdsToProgram', {
        orgId,
        programId,
        selectedIds,
      })

      const endsOn = program?.endsOn
        ? program.endsOn
        : getProgramEndsOnFromTemplateExpiry({
            templateExpiry: template?.expiry,
          })

      updateProgram({
        orgId,
        programId,
        program: {
          status: ProgramStatus.active,
          draftData: deleteField(),
          recipientFilters: draftData?.recipientFilters,
          endsOn,
        },
      })
        .then(() => {
          toaster.success('Payment succeeded')

          const peopleIds = isDirectMail ? [individual.id] : recipientIds

          callFunction('mailjet-SendBulk', {
            orgId,
            programId,
            logo: org.logoUrl || PERKUP_PRIMARY_LOGO,
            banner: program?.email?.banner,
            title: program?.email?.title,
            programName: program?.email?.title,
            programNote: program?.note,
            fromName: program?.email?.fromName,
            peopleIds,
            emails: recipientEmails,
            color: defaultColor,
          })

          if (isDirectMail) {
            sendDirectMailGift.current = true
          } else {
            copyProgramAcceptanceLink(programId)
            navigate(
              `${DEFAULT_ROUTES.ORGANIZATION.REWARDS.ROOT}/${programId}`,
              { state: { confetti: true } }
            )
          }
        })
        .finally(() => {
          setIsLoadingAccount(true)
          setIsSendingReward(false)
        })
    }
  }

  if (!program || !stripe) {
    return <NewRewardCheckoutEmptyState stripe={stripe} />
  }

  const sentryContext = {
    contexts: {
      EmbeddedCheckout: {
        clientSecret,
        amount: totalAmount,
      },
    },
  }

  const handleSubmit = async () => {
    if (!elements) {
      toaster.warning('Something went wrong.')
      captureMessage('Stripe elements not found.', sentryContext)
      return
    }

    if (!stripe) {
      toaster.warning('Something went wrong.')
      captureMessage('Stripe not initialized.', sentryContext)
      return
    }

    setIsLoading(true)
    const { error } = await stripe.confirmPayment({
      elements,
      confirmParams: { return_url: undefined },
      redirect: 'if_required',
    })
    if (!error) {
      const { paymentIntent } = await stripe.retrievePaymentIntent(clientSecret)
      if (paymentIntent) {
        await handleSubmitProgram(paymentIntent)
        switch (paymentIntent.status) {
          case 'succeeded':
            break
          case 'processing':
            toaster.notify('Your payment is processing.')
            break
          case 'requires_payment_method':
            toaster.warning(
              'Your payment was not successful, please try again.'
            )
            break
          default:
            toaster.warning('Something went wrong.')
            captureMessage('Unexpected payment intent status.', sentryContext)
            break
        }
      }
    }
    if (error && error.message) {
      captureException(error, sentryContext)
      if (error.type === 'card_error' || error.type === 'validation_error') {
        toaster.warning(error.message)
      } else {
        toaster.warning('An unexpected error occurred.')
      }
    }
    setIsLoading(false)
  }

  return (
    <Pane
      maxWidth={PAGE_MAX_WIDTH}
      paddingRight={64}
      paddingLeft={32}
      margin="auto"
      display="flex"
      flexDirection="column"
      alignItems="center"
      gap={32}
      marginTop={32}
    >
      <Pane display="flex" flexDirection="column" gap={32}>
        <Pane display="flex" gap={32}>
          <Pane>
            <Heading size={600} marginBottom={32}>
              Order Summary
            </Heading>
            <PriceBreakdown
              program={program}
              recipientCount={recipientCount}
              subtotal={subtotal}
              feesAmount={feesAmount}
              totalAmount={totalAmount}
            />
          </Pane>
          <Pane>
            <Flex vertical gap={16}>
              <Heading size={600}>Payment method</Heading>
              <Pane
                maxWidth={600}
                display="flex"
                flexDirection="column"
                gap={16}
              >
                <Form onFinish={handleSubmit}>
                  <PaymentElement id="customStripeElement" />

                  <Pane
                    display="flex"
                    flexDirection="column"
                    gap={8}
                    marginTop={32}
                  >
                    <Button
                      size="large"
                      type="primary"
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                      }}
                      disabled={productsOutOfStock}
                      loading={isLoading}
                      icon={<LockFilled />}
                      htmlType="submit"
                    >
                      Pay {numToDollars(totalAmount, 2)}
                    </Button>
                    <Text color="muted">
                      By confirming your payment, you allow PerkUp Inc. to
                      charge your card for this payment in accordance with their
                      terms.
                    </Text>
                  </Pane>
                </Form>
              </Pane>
            </Flex>

            {isLoadingAccount && (
              <Pane
                display="flex"
                alignItems="center"
                gap={16}
                justifyContent="center"
              >
                <Text color="muted">Saving</Text>
                <LoadingOutlined style={{ fontSize: 24 }} spin />
              </Pane>
            )}
          </Pane>
        </Pane>
      </Pane>
    </Pane>
  )
}

export default function NewRewardCheckout() {
  const program = useContext(ProgramContext)
  const org = useContext(OrgContext)
  const orgId = org.id
  const { customerId } = org

  const [clientSecret, setClientSecret] = useState<string>()
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false)
  const { stripe } = useStripe()

  const accountId = program?.accountId

  const programBudget = program?.budget

  const draftData = parseDraftData({ program })
  const recipientIds = draftData?.individuals || []
  const recipientEmails = draftData?.emails || []

  // If program is direct, recipientCount is 1
  const isDirectMail = program?.type === ProgramType.direct
  const recipientCount = getRewardQuantity({
    recipientCount: recipientEmails.length + recipientIds.length,
    isDirectMail,
  })

  const { subtotal } = useMemo(() => {
    return calcRewardAmounts({ programBudget, recipientCount })
  }, [programBudget, recipientCount])

  useEffect(() => {
    if (accountId && subtotal) {
      setIsLoadingClientSecret(true)
      CreateTopUpInvoice({
        orgId,
        accountId,
        amount: subtotal,
        paymentMethod: 'card',
        customer: customerId,
        isEmbeddedCheckout: true,
      })
        .then(res => {
          if (res) setClientSecret(res.paymentIntentClientSecret)
        })
        .catch(error => {
          console.error(error)
          captureException(toSentry(error), {
            contexts: {
              CreateTopUpInvoice: { orgId, accountId },
            },
          })
        })
        .finally(() => setIsLoadingClientSecret(false))
    }
  }, [subtotal, accountId, customerId, orgId])

  const options: StripeElementsOptions = useMemo(() => {
    return {
      appearance: { theme: 'stripe' },
      clientSecret,
    }
  }, [clientSecret])

  if (isLoadingClientSecret) return <PerkLoader />

  // Client secret is undefined because total amount is 0
  if (clientSecret && stripe && accountId) {
    return (
      <Elements stripe={stripe} options={options}>
        <CheckoutContainer
          stripe={stripe}
          clientSecret={clientSecret}
          program={program}
          recipientIds={recipientIds}
          recipientEmails={recipientEmails}
        />
      </Elements>
    )
  }

  return <NewRewardCheckoutEmptyState stripe={stripe} />
}
