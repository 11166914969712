import {
  EditOutlined,
  EllipsisOutlined,
  EyeOutlined,
  MinusCircleOutlined,
  MinusOutlined,
  PlusOutlined,
  SendOutlined,
} from '@ant-design/icons'
import {
  Button,
  Dropdown,
  Empty,
  Flex,
  Grid,
  Input,
  Modal,
  Tooltip,
} from 'antd'
import {
  removeCollectionProducts,
  updateCollectionProducts,
} from 'api/databaseCalls'
import {
  CountryIconGroup,
  PerkLoader,
  PerkScrollbars,
  PrepaidBadge,
  ProductCollectionThumbnail,
  ProductCollectionsPermissionsTable,
  ProductImages,
  ProductOptions,
  TextEditor,
  VariantEstimatedShippingTime,
  VariantQuantityTable,
} from 'components'
import { AsyncButton } from 'components/Buttons/AsyncButton'
import { defaultButtonStyles } from 'constants/antdesign'
import { NUMBER_GREEN } from 'constants/colors'
import { DEFAULT_ROUTES } from 'constants/routes'
import { IndividualContext } from 'context'
import { Heading, Pane, Strong, Text, toaster } from 'evergreen-ui'
import { Individual_Role } from 'gen/perkup/v1/individual_pb'
import { ProductCollection_Permission } from 'gen/perkup/v1/product_collections_pb'
import {
  ProductVariant,
  ProductVariant_Image,
  ProductVariant_SourceType,
} from 'gen/perkup/v1/product_variant_pb'
import { useAllowedCollections } from 'hooks'
import useListenToProductVariantsByProductId from 'hooks/productVariants/useListenToProductVariantsByProductId'
import { compact, isEmpty, maxBy } from 'lodash-es'
import { buildProgramGiftAsGenericProduct } from 'pages/NewReward/utils/program-gifts'
import { useContext, useState } from 'react'
import { useNavigate } from 'react-router'
import { UpdateProduct } from 'services/shopify'
import { WithSelectedQuantity } from 'types'
import { Mode } from 'types/edit'
import {
  calculateShopifyProductVariantTotal,
  makePlural,
  numToDollars,
} from 'utils'
import {
  PRODUCT_COLLECTION_LEVELS,
  getHighestPermission,
  getHighestPermissionByKey,
  permissionSatisfies,
} from 'utils/permissions'

const { useBreakpoint } = Grid

export function SwagProductDetails({ productId }: { productId: string }) {
  const navigate = useNavigate()

  const individual = useContext(IndividualContext)

  const [mode, setMode] = useState(Mode.view)
  const [showCollectionsModal, setShowCollectionsModal] = useState(false)

  const { allProductVariants, hasLoaded: hasLoadedPvs } =
    useListenToProductVariantsByProductId({
      productId,
    })

  const screens = useBreakpoint()

  const isAdmin = individual.role === Individual_Role.admin
  const isInEditMode = mode === Mode.edit
  const defaultProductVariant = maxBy(allProductVariants, 'amount')
  const {
    allowedCollections: collectionsToDisplay,
    isLoading: isLoadingCollections,
  } = useAllowedCollections({
    minPermission: ProductCollection_Permission.view,
  })
  const productCollectionsWithProduct = collectionsToDisplay.filter(
    pc => pc.products[productId]
  )

  if (!hasLoadedPvs || isLoadingCollections) return <PerkLoader />

  if (!defaultProductVariant) {
    return (
      <Empty
        image={Empty.PRESENTED_IMAGE_SIMPLE}
        imageStyle={{ height: 60 }}
        description={
          <Flex vertical gap={8}>
            <Heading>No product found</Heading>
            <Text>We couldn&apos;t find exactly what you were looking for</Text>
          </Flex>
        }
      />
    )
  }

  const handleSendSwagReward = (productVariant: ProductVariant) => {
    const gift = buildProgramGiftAsGenericProduct({ productVariant })
    const total = calculateShopifyProductVariantTotal(productVariant)
    navigate(DEFAULT_ROUTES.ORGANIZATION.REWARDS.NEW_REWARD, {
      state: { gift, budget: total },
    })
  }

  const handleSendSwagToAddress = (productVariant: ProductVariant) => {
    const pvWithQuantity: WithSelectedQuantity<ProductVariant> = Object.assign(
      productVariant,
      {
        selectedQuantity: 1,
      }
    )
    navigate(DEFAULT_ROUTES.ORGANIZATION.ORDER.SWAG, {
      state: { productVariants: [pvWithQuantity] },
    })
  }

  const handleProductTitleUpdate = (
    e: React.FocusEvent<HTMLInputElement, Element>
  ) => {
    UpdateProduct({
      id: `gid://shopify/Product/${productId}`,
      title: e.target.value,
    })
      .then(res => {
        if (res?.product?.title) {
          toaster.success(`Updated product title`)
        }
      })
      .catch(() => {
        toaster.warning('Error updating product title')
      })
  }

  const handleProductDescriptionUpdate = (descriptionHtml: string) => {
    UpdateProduct({
      id: `gid://shopify/Product/${productId}`,
      descriptionHtml,
    })
      .then(res => {
        if (res?.product?.descriptionHtml) {
          toaster.success(`Updated product description`)
        }
      })
      .catch(() => {
        toaster.warning('Error updating product title')
      })
  }

  const handleUpdateProductThumbnailImage = (
    thumbnailImage: ProductVariant_Image
  ) => {
    const newOrderForImages = [
      thumbnailImage,
      ...defaultProductVariant.productImages.filter(
        image => image.id !== thumbnailImage.id
      ),
    ]

    UpdateProduct({
      id: `gid://shopify/Product/${productId}`,
      images: newOrderForImages.map(image => ({
        id: image.id,
      })),
    }).catch(() => {
      toaster.warning('Error updating product thumbnail')
    })
  }

  const handleRemoveProductFromCollection = async (collectionId: string) => {
    await removeCollectionProducts({
      collectionId,
      productIds: [productId],
    })
  }

  const handleAddProductToCollection = async (collectionId: string) => {
    await updateCollectionProducts({
      collectionId,
      productIds: [productId],
    })
  }

  const disableCtas = isInEditMode || !defaultProductVariant.productIsAvailable

  const allProductPermissions = getHighestPermissionByKey(
    PRODUCT_COLLECTION_LEVELS
  )(
    productCollectionsWithProduct.map(collection => ({
      from: collection,
      permissions: collection.permissions,
    }))
  )

  const inidividualPermission = getHighestPermission(PRODUCT_COLLECTION_LEVELS)(
    {
      role: individual.role,
      individualId: individual.id,
      permissions: Object.fromEntries(
        Object.entries(allProductPermissions).map(([id, permission]) => [
          id,
          permission.permission,
        ])
      ),
    }
  )

  const allowedToEdit = permissionSatisfies(PRODUCT_COLLECTION_LEVELS)(
    inidividualPermission,
    ProductCollection_Permission.full
  )

  const allowedToSend = permissionSatisfies(PRODUCT_COLLECTION_LEVELS)(
    inidividualPermission,
    ProductCollection_Permission.full
  )

  const settingsMenu = allowedToEdit ? (
    <Dropdown
      placement="bottomRight"
      menu={{
        items: [
          {
            key: '1',
            label: (
              <Flex gap={8} align="center">
                {isInEditMode ? <EyeOutlined /> : <EditOutlined />}
                <Text>{isInEditMode ? 'View' : 'Edit'} product info</Text>
              </Flex>
            ),
            onClick: () => setMode(isInEditMode ? Mode.view : Mode.edit),
          },
        ],
      }}
    >
      <Button style={{ minWidth: 32 }} icon={<EllipsisOutlined />} />
    </Dropdown>
  ) : null

  return (
    <>
      {/** PRODUCT DETAILS */}
      <Flex gap={32} style={{ width: '100%' }}>
        {/** PRODUCT IMAGES WITH COLLECTIONS DETAILS */}
        <Flex flex={1} vertical gap={32}>
          <ProductImages
            key={defaultProductVariant?.productImages[0]?.id}
            productImages={defaultProductVariant.productImages}
            onConfirmThumbnail={
              isAdmin ? handleUpdateProductThumbnailImage : undefined
            }
          />

          <Flex vertical gap={16}>
            <Flex justify="space-between" align="center">
              <Heading size={600}>Collections</Heading>
              <Button
                icon={<PlusOutlined />}
                onClick={() => setShowCollectionsModal(true)}
              >
                Add to collection
              </Button>
            </Flex>
            {isEmpty(productCollectionsWithProduct) ? (
              <Text color="muted">This product is not in any collections.</Text>
            ) : (
              productCollectionsWithProduct.map(pc => (
                <ProductCollectionThumbnail key={pc.id} productCollection={pc}>
                  <ProductCollectionThumbnail.Details />
                  <ProductCollectionThumbnail.PriceRange />
                  <ProductCollectionThumbnail.Cta>
                    <Tooltip title="Remove from collection">
                      <AsyncButton
                        type="text"
                        danger
                        icon={<MinusCircleOutlined />}
                        onClick={() => handleRemoveProductFromCollection(pc.id)}
                      />
                    </Tooltip>
                  </ProductCollectionThumbnail.Cta>
                </ProductCollectionThumbnail>
              ))
            )}
          </Flex>

          <Flex vertical gap={16}>
            <Heading size={600}>Inventory</Heading>
            <VariantQuantityTable variants={allProductVariants} />
          </Flex>
          <Flex vertical gap={24}>
            <Flex vertical gap={4}>
              <Heading size={600}>Permissions</Heading>
              <Text size={400}>
                To change a product&apos;s permissions, update the permissions
                of its collection.
              </Text>
            </Flex>
            <ProductCollectionsPermissionsTable
              collections={productCollectionsWithProduct}
            />
          </Flex>
        </Flex>

        {/** STICKY PRODUCT INFO */}

        <Pane
          flex={1}
          width="100%"
          position="sticky"
          top={96}
          display="flex"
          flexDirection="column"
          gap={16}
          padding={screens.xl || screens.xxl ? 32 : 24}
          borderRadius={8}
          elevation={2}
          maxHeight="calc(100vh - 136px)"
          height="max-content"
        >
          {/** EDIT MODE BANNER */}
          {isInEditMode && (
            <Flex
              gap={8}
              align="center"
              style={{
                borderRadius: 4,
                backgroundColor: '#ABABAB',
                padding: '8px 12px',
                color: 'white',
              }}
              justify="space-between"
            >
              <Flex gap={8}>
                <EditOutlined />
                <Text color="inherit">You are in Edit Mode</Text>
              </Flex>

              <Button
                size="small"
                type="primary"
                onClick={() => setMode(Mode.view)}
              >
                View Mode
              </Button>
            </Flex>
          )}

          {/** PRODUCT TYPE WITH MENU */}
          {/** Don't render this entire row (including the settings menu) if there's no product type */}
          {defaultProductVariant.productType && (
            <Flex justify="space-between" align="center">
              <Heading size={100}>{defaultProductVariant.productType}</Heading>

              {settingsMenu}
            </Flex>
          )}

          {/** PRODUCT TITLE WITH MENU */}

          <Flex justify="space-between" align="center" gap={16}>
            {isInEditMode ? (
              <Input
                defaultValue={defaultProductVariant.productName}
                onBlur={handleProductTitleUpdate}
              />
            ) : (
              <Heading size={800} className="two-line-truncate">
                {defaultProductVariant.productName}
              </Heading>
            )}

            {/** If there's no product type, let's render the settings menu over here! */}
            {!defaultProductVariant.productType && settingsMenu}
          </Flex>

          {/** PRODUCT PRICE */}
          <Flex gap={16} align="center">
            <Strong size={600} color={NUMBER_GREEN}>
              {numToDollars(Number(defaultProductVariant.amount))}
            </Strong>

            {defaultProductVariant?.sourceType ===
              ProductVariant_SourceType.fullPrepaid && <PrepaidBadge />}

            {defaultProductVariant?.productIsAvailable ? (
              <Text color="muted">In stock</Text>
            ) : (
              <Text color="danger">Out of stock</Text>
            )}
          </Flex>

          {/** CTAS */}
          <Flex
            gap={screens.xxl ? 16 : 8}
            align="center"
            vertical={!screens.xxl}
          >
            <Tooltip
              title={
                allowedToSend
                  ? undefined
                  : "You don't have permission to send this product"
              }
            >
              <Button
                type="default"
                size={screens.xl || screens.xxl ? 'large' : 'middle'}
                onClick={() => handleSendSwagToAddress(defaultProductVariant)}
                disabled={disableCtas || !allowedToSend}
                style={{ flex: 1, width: '100%' }}
              >
                Send to address
              </Button>
            </Tooltip>
            <Tooltip
              title={
                allowedToSend
                  ? undefined
                  : "You don't have permission to send this product"
              }
            >
              <Button
                type="primary"
                size={screens.xl || screens.xxl ? 'large' : 'middle'}
                onClick={() => handleSendSwagReward(defaultProductVariant)}
                style={{
                  ...defaultButtonStyles,
                  gap: 8,
                  flex: 1,
                  width: '100%',
                }}
                disabled={disableCtas || !allowedToSend}
              >
                Send as reward <SendOutlined />
              </Button>
            </Tooltip>
          </Flex>

          <ProductOptions product={defaultProductVariant} asStatic />

          <VariantEstimatedShippingTime
            productVariant={defaultProductVariant}
            quickest
          />

          {compact(defaultProductVariant.shippingCountries).length > 0 && (
            <CountryIconGroup iso3s={defaultProductVariant.shippingCountries} />
          )}

          <TextEditor
            htmlContent={defaultProductVariant.description || ''}
            onBlur={handleProductDescriptionUpdate}
            persistEditorMode={isInEditMode}
            disabled={!isInEditMode}
          />
        </Pane>
      </Flex>

      {/** COLLECTIONS MODAL */}
      <Modal
        title="Add product to collection"
        open={showCollectionsModal}
        footer={null}
        onCancel={() => setShowCollectionsModal(false)}
        afterClose={() => setShowCollectionsModal(false)}
        width={720}
        destroyOnClose
      >
        <PerkScrollbars style={{ height: '60vh' }}>
          <Flex vertical gap={32}>
            <Text color="muted">{`This product is in ${productCollectionsWithProduct.length} ${makePlural('collection', productCollectionsWithProduct.length)}`}</Text>
            <Flex vertical gap={16}>
              {collectionsToDisplay.map(pc => {
                const productInCollection = !!pc.products[productId]

                return (
                  <ProductCollectionThumbnail
                    key={pc.id}
                    productCollection={pc}
                  >
                    <ProductCollectionThumbnail.Details />
                    <ProductCollectionThumbnail.PriceRange />
                    <ProductCollectionThumbnail.Cta>
                      <Tooltip
                        title={
                          productInCollection
                            ? 'Remove from collection'
                            : 'Add to collection'
                        }
                      >
                        <AsyncButton
                          type="default"
                          danger={productInCollection}
                          style={{ width: 104 }}
                          icon={
                            productInCollection ? (
                              <MinusOutlined />
                            ) : (
                              <PlusOutlined />
                            )
                          }
                          onClick={() => {
                            if (productInCollection) {
                              handleRemoveProductFromCollection(pc.id)
                            } else {
                              handleAddProductToCollection(pc.id)
                            }
                          }}
                        >
                          {productInCollection ? 'Remove' : 'Add'}
                        </AsyncButton>
                      </Tooltip>
                    </ProductCollectionThumbnail.Cta>
                  </ProductCollectionThumbnail>
                )
              })}
            </Flex>
          </Flex>
        </PerkScrollbars>
      </Modal>
    </>
  )
}
