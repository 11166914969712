import { captureException } from '@sentry/react'
import { ReactComponent as PageNotFoundIllustration } from 'assets/PageNotFound.svg'
import { Footer, PerkLoader } from 'components'
import * as EVENTS from 'constants/events'
import { localStorageEmailForSignIn } from 'constants/firebaseConfig'
import * as ROUTES from 'constants/routes'
import { PROGRAM_ID } from 'constants/sessionOrLocalStorage'
import { OrgContext, UserContext } from 'context'
import { Button, Heading, Pane, Text, toaster } from 'evergreen-ui'
import {
  getAuth,
  isSignInWithEmailLink,
  signInWithEmailLink,
} from 'firebase/auth'
import { useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { logEvent } from 'utils'
import { toSentry } from 'utils/sentry'

export default function EmailConfirmation() {
  const navigate = useNavigate()
  const auth = getAuth()
  const org = useContext(OrgContext)
  const orgId = org?.id
  const user = useContext(UserContext)
  const userId = user?.id
  const programId = window.localStorage.getItem(PROGRAM_ID)

  if (isSignInWithEmailLink(auth, window.location.href)) {
    const email = window.localStorage.getItem(localStorageEmailForSignIn)

    if (!email) {
      toaster.warning(
        'Something went wrong. Please try again or contact support'
      )
      navigate(ROUTES.SIGN_IN)

      return (
        <Pane maxWidth={1092} marginTop={68} marginX="auto">
          <Pane
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Pane marginRight={32}>
              <Heading size={900} fontSize={64} lineHeight="1">
                Error
              </Heading>
              <Text size={600}>The following email link has expired.</Text>
              <Pane>
                <Button
                  appearance="primary"
                  autoFocus
                  onClick={() => navigate(ROUTES.HOME)}
                  marginTop={32}
                  size="large"
                >
                  Send new link
                </Button>
              </Pane>
            </Pane>
            <Pane>
              <PageNotFoundIllustration
                style={{ width: '100%', maxWidth: 640, height: 'auto' }}
              />
            </Pane>
          </Pane>
          <Footer />
        </Pane>
      )
    }

    signInWithEmailLink(auth, email, window.location.href)
      .then(() => {
        window.localStorage.removeItem(localStorageEmailForSignIn)

        const afterSignInRoute = programId
          ? ROUTES.REWARD_ACCEPTANCE.replace(':programId', programId)
          : ROUTES.HOME
        navigate(afterSignInRoute)

        // Track Email Sign In
        logEvent(EVENTS.SIGNED_IN, { authProvider: 'Email', orgId, userId })

        // You can access the new user via result.user
        // Additional user info profile not available via:
        // result.additionalUserInfo.profile == null
        // You can check if the user is new or existing:
        // result.additionalUserInfo.isNewUser
      })
      .catch(error => {
        console.error(error)
        if (error.code === 'auth/invalid-action-code') {
          // Thrown if the action code is invalid.
          // This can happen if the code is malformed or has already been used.
          toaster.warning(
            'Link is no longer valid. Please try again or contact support',
            { id: error.message }
          )
          // Do not send to Sentry
        } else {
          captureException(toSentry(error), {
            contexts: {
              EmailConfirmation: {
                email,
              },
            },
          })
        }

        navigate(ROUTES.SIGN_IN)
      })
  }

  return (
    <Pane margin={16}>
      <Heading size={800}>Signing into PerkUp...</Heading>
      <PerkLoader />
    </Pane>
  )
}
