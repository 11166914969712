import { AlertProps, Drawer, Flex } from 'antd'
import { PerkScrollbars, ProductsGrid } from 'components'
import { ExchangeRateContext } from 'context'
import { Pane } from 'evergreen-ui'
import { AlgoliaBrowseProducts, ProductDetails } from 'features'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { useDisplayCurrency } from 'hooks/useDisplayCurrency'
import { isUndefined, uniqBy } from 'lodash-es'
import { useContext, useMemo, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { InstantSearchProps } from 'react-instantsearch'
import { numToDollars } from 'utils'
import {
  getProductVariantOptions,
  getUniqAndFormatWithSelectable,
} from 'utils/productVariant'

export function SelectProductVariants({
  productVariantsAvailable = [],
  selectedVariants,
  onAddVariant,
  showPrice = false,
  budgetRemaining,
  giftsRemaining,
  continueFooter,
  header,
  withAlgolia = false,
  initialUiState,
  searchFilter,
  maxAmount,
}: {
  productVariantsAvailable?: ProductVariant[]
  selectedVariants: ProductVariant[]
  onAddVariant: (productVariant: ProductVariant) => void
  showPrice?: boolean
  budgetRemaining?: number
  giftsRemaining?: number
  continueFooter?: JSX.Element
  header?: JSX.Element
  withAlgolia?: boolean
  initialUiState?: InstantSearchProps['initialUiState']
  searchFilter?: string
  maxAmount?: number
}) {
  const exchangeRate = useContext(ExchangeRateContext)
  const displayCurrency = useDisplayCurrency()
  const [toggledProductVariant, setToggledProductVariant] =
    useState<ProductVariant>()

  const specificVariantOutOfStock = !toggledProductVariant?.isAvailable
  const handleSelectProductVariant = (productVariant: ProductVariant) => {
    onAddVariant(productVariant)
    setToggledProductVariant(undefined)
  }

  const handleChangeVariant = (productVariant?: ProductVariant) => {
    if (!productVariant) return
    setToggledProductVariant(productVariant)
    const productIsAdded = selectedVariants.some(
      pv => pv.productId === productVariant.productId
    )

    const variantIsAdded = selectedVariants.some(
      pv => pv.id === productVariant.id
    )

    if (productIsAdded && !variantIsAdded) {
      // Will remove the added product when toggling a different variant
      onAddVariant(productVariant)
    }
  }

  const handleToggleProductVariant = (productVariant: ProductVariant) => {
    if (toggledProductVariant?.productId === productVariant.productId) {
      setToggledProductVariant(undefined)
    } else {
      setToggledProductVariant(productVariant)
    }
  }

  const currentVariantIsSelected = useMemo(() => {
    if (!toggledProductVariant) return false
    return !!selectedVariants.find(
      variant => variant.productId === toggledProductVariant.productId
    )
  }, [toggledProductVariant, selectedVariants])

  const maxGiftsSelected = !isUndefined(giftsRemaining) && giftsRemaining === 0

  const oneGiftRemaining = !isUndefined(giftsRemaining) && giftsRemaining === 1

  const determinePrimaryCtaText = () => {
    if (currentVariantIsSelected) return 'Unselect'

    if (specificVariantOutOfStock) return 'Out of stock'

    if (oneGiftRemaining) return 'Select and continue'

    return 'Select'
  }

  const productExceedsBudget =
    !isUndefined(budgetRemaining) &&
    !!toggledProductVariant?.amount &&
    toggledProductVariant.amount > budgetRemaining

  const disableSelectVariant =
    ((maxGiftsSelected || productExceedsBudget) && !currentVariantIsSelected) ||
    specificVariantOutOfStock

  // Toggled variant already selected
  const selectedToggledVariant = selectedVariants.find(
    av => av.productId === toggledProductVariant?.productId
  )

  const getAlertInfo = () => {
    if (productExceedsBudget)
      return {
        message: "You don't have enough funds",
        description: `You have ${numToDollars(
          budgetRemaining * exchangeRate,
          2,
          false,
          displayCurrency
        )} to spend.`,
      }

    if (maxGiftsSelected) {
      if (selectedVariants.length === 1) {
        return {
          message: 'You have already selected your gift.',
          description: 'To select this item, unselect the gift.',
        }
      }
      return {
        message: `You have already selected ${selectedVariants.length} gifts.`,
        description:
          'To select this item, unselect one of your selected items.',
      }
    }

    return undefined
  }

  const alertInfo = getAlertInfo()

  const showAlert = !!alertInfo && disableSelectVariant

  const alertProps: AlertProps | undefined = showAlert
    ? {
        message: alertInfo?.message,
        description: alertInfo?.description,
        type: 'warning',
        showIcon: true,
      }
    : undefined

  const uniqueSelectedVariants =
    getUniqAndFormatWithSelectable(selectedVariants)

  // In bare products grid, need to also render variants that were added via swap
  const productsGridProducts = uniqBy(
    [...uniqueSelectedVariants, ...productVariantsAvailable],
    'productId'
  )

  const defaultSelectedVariant = !selectedToggledVariant
    ? undefined
    : {
        options: getProductVariantOptions({
          productVariant: selectedToggledVariant,
        }),
        filterOtherOptions: false,
      }

  return (
    <Flex style={{ height: '100%' }} vertical={isMobile} gap={8}>
      <Flex
        vertical
        justify="space-between"
        style={{ height: '100%', maxWidth: '100%' }}
        flex={2}
      >
        <Pane display="flex" flexDirection="column" gap={16}>
          {header}
          <Pane height="100%" paddingBottom={40}>
            {withAlgolia ? (
              <AlgoliaBrowseProducts initialUiState={initialUiState}>
                <AlgoliaBrowseProducts.Old
                  onProductCardClick={handleToggleProductVariant}
                  withCategoriesFilter
                  withProductAmounts={showPrice}
                  searchFilter={searchFilter}
                  selectedVariants={uniqueSelectedVariants}
                  showSelectedVariantsFirst={false}
                />
              </AlgoliaBrowseProducts>
            ) : (
              <ProductsGrid
                products={productsGridProducts}
                onCardClick={handleToggleProductVariant}
                withPrices={showPrice}
              />
            )}
          </Pane>
        </Pane>
        {!isMobile && continueFooter}
      </Flex>
      {toggledProductVariant && (
        <Drawer
          placement="right"
          key={toggledProductVariant.productId}
          open
          onClose={() => setToggledProductVariant(undefined)}
          width={600}
          styles={{ body: { padding: 0 } }}
        >
          <PerkScrollbars style={{ height: '100%' }}>
            <Pane
              display="flex"
              flexDirection="column"
              padding={isMobile ? 16 : 24}
            >
              <ProductDetails
                productVariant={selectedToggledVariant || toggledProductVariant}
                onSubmit={handleSelectProductVariant}
                showPrice={showPrice}
                disableSubmit={disableSelectVariant}
                showShipping={false}
                isVerticalLayout
                defaultSelected={defaultSelectedVariant}
                alertProps={alertProps}
                submitButtonProps={{
                  type: currentVariantIsSelected ? 'default' : 'primary',
                  children: determinePrimaryCtaText(),
                }}
                maxAmount={maxAmount}
                onSelectedVariantChange={handleChangeVariant}
              />
            </Pane>
          </PerkScrollbars>
        </Drawer>
      )}
      {isMobile && continueFooter}
    </Flex>
  )
}
