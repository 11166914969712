import { ANT_DESIGN_DATE_FORMAT } from 'constants/insights'
import type { DateRangeArray, DayJsDateRange } from 'features/insights'

export function dayJsDateRangeToApiDateRange(
  range: DayJsDateRange | undefined
): DateRangeArray | undefined {
  if (!range) {
    return undefined
  }
  return range.map(d => d.format(ANT_DESIGN_DATE_FORMAT)) as DateRangeArray
}

export const isDateRangeGreaterThan = (range: DayJsDateRange, days: number) =>
  range[1].diff(range[0], 'days') >= days

export function dateRangeToJoinedString(
  range: DayJsDateRange | undefined,
  format: string
): string {
  if (!range) {
    return ''
  }
  return range.map(d => d.format(format)).join(' - ')
}

export function determineGranularity(dateRange: DayJsDateRange | undefined) {
  // if no date range is set, use month granularity
  // since this means that the user is looking at historical data
  if (!dateRange) return 'month'

  // If date range is greater than 364 days, use month granularity
  if (isDateRangeGreaterThan(dateRange, 364)) return 'month'

  // If date range is greater than 89 days, use week granularity
  if (isDateRangeGreaterThan(dateRange, 89)) return 'week'

  return 'day'
}
