import { LockFilled } from '@ant-design/icons'
import {
  CustomCheckoutProvider,
  PaymentElement,
  useCustomCheckout,
} from '@stripe/react-stripe-js'
import { Alert, Button, Flex, Form, message } from 'antd'
import { callFunction } from 'api/functionCalls'
import { PerkEmpty, PerkLoader } from 'components'
import {
  MAX_PERSONAL_FUNDS_BALANCE,
  MIN_PERSONAL_FUNDS_DEPOSIT,
} from 'constants/money'
import { OrgContext, OrgUserContext, UserContext } from 'context'
import { Text } from 'evergreen-ui'
import { Organization_SubscriptionStatus } from 'gen/perkup/v1/organization_pb'
import { CheckoutProduct } from 'gen/perkup/v1/stripe_pb'
import { useOrgUserBalances } from 'hooks'
import { useStripe } from 'hooks/Stripe/useStripe'
import { isString } from 'lodash-es'
import { useContext, useEffect, useState } from 'react'
import { numToDollars } from 'utils'

function PersonalFundsPaymentForm({
  amount,
  onComplete,
  disabled = false,
}: {
  amount: number
  onComplete: () => void
  disabled?: boolean
}) {
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [messageApi, contextHolder] = message.useMessage()

  const { confirm, canConfirm } = useCustomCheckout()

  const handleConfirmClick = () => {
    setIsLoadingSubmit(true)
    confirm({ redirect: 'if_required' }).then(result => {
      if (result.error) {
        messageApi.warning(result.error.message)
        return
      }
      onComplete()
    })
    // No need to set isLoadingSubmit to false, because the modal will close or we'll redirect
  }

  const disableCtaClick = isLoadingSubmit || disabled || !canConfirm

  return (
    <>
      {contextHolder}
      <Form onFinish={handleConfirmClick}>
        <Flex vertical gap={16}>
          <div style={{ margin: '-10px -12px' }}>
            <PaymentElement id="customStripeElement" />
          </div>
          <Button
            size="large"
            type="primary"
            loading={isLoadingSubmit}
            icon={<LockFilled />}
            htmlType="submit"
            disabled={disableCtaClick}
          >
            Pay {numToDollars(amount, 2)}
          </Button>
          <Text color="muted">
            By confirming your payment, you allow PerkUp Inc. to charge your
            card for this payment in accordance with their terms.
          </Text>
        </Flex>
      </Form>
    </>
  )
}

export function PersonalFundsPayment({
  amount,
  onComplete,
  disabled = false,
  product = CheckoutProduct.PERSONAL_FUNDS,
  customerCreationIdempotencyKey,
}: {
  amount: number
  onComplete: () => void
  disabled?: boolean
  product?: CheckoutProduct
  customerCreationIdempotencyKey?: string
}) {
  const { subscriptionStatus, id: orgId } = useContext(OrgContext)
  const isActiveOrg =
    subscriptionStatus === Organization_SubscriptionStatus.active
  const orgUser = useContext(OrgUserContext)
  const { id: userId, profile } = useContext(UserContext)
  const { customerId } = orgUser
  const [clientSecret, setClientSecret] = useState<string>()
  const [isLoadingClientSecret, setIsLoadingClientSecret] = useState(false)
  const [isLoadingCreateCustomer, setIsLoadingCreateCustomer] = useState(false)
  const { stripe, isLoading: isLoadingStripe } = useStripe()
  const { orgUserPersonalBalance } = useOrgUserBalances()

  useEffect(() => {
    if (customerId) return
    setIsLoadingCreateCustomer(true)
    callFunction('stripe-CreateOrgUserCustomer', {
      name: `${profile?.firstName} ${profile?.lastName}`,
      email: profile?.email,
      userId,
      orgId,
      idempotencyKey: `customer_${customerCreationIdempotencyKey}`,
    }).finally(() => setIsLoadingCreateCustomer(false))
  }, [customerCreationIdempotencyKey, customerId, orgId, profile, userId])

  useEffect(() => {
    if (!customerId || !isActiveOrg) {
      setIsLoadingClientSecret(false)
      return
    }

    setIsLoadingClientSecret(true)
    callFunction('stripe-CreatePersonalFundsCheckoutSession', {
      budget: amount,
      customer: customerId,
      idempotencyKey: `checkout_${crypto.randomUUID()}`,
      product,
    })
      .then(res => {
        if (!res) return
        if (isString(res)) setClientSecret(res)
      })
      .finally(() => setIsLoadingClientSecret(false))
  }, [amount, customerId, isActiveOrg, product])

  if (isLoadingClientSecret || isLoadingCreateCustomer || isLoadingStripe) {
    return (
      <Flex vertical style={{ height: '100%', margin: -48 }} justify="center">
        <PerkLoader />
      </Flex>
    )
  }

  if (!clientSecret || !stripe)
    return (
      <PerkEmpty header="Looks like we made a mistake, please try again or contact suppport" />
    )

  const getDisabledAlertText = () => {
    if (!isActiveOrg) {
      return 'Please upgrade your organization subscription to active to add personal funds'
    }
    if (amount < MIN_PERSONAL_FUNDS_DEPOSIT) {
      return `Please enter an amount greater than ${numToDollars(
        MIN_PERSONAL_FUNDS_DEPOSIT
      )}`
    }
    if (amount + orgUserPersonalBalance > MAX_PERSONAL_FUNDS_BALANCE) {
      return `Your personal funds can not exceed ${numToDollars(
        MAX_PERSONAL_FUNDS_BALANCE
      )}`
    }
    return undefined
  }

  const disabledAlertText = getDisabledAlertText()

  if (disabledAlertText) {
    return <Alert message={disabledAlertText} showIcon type="warning" />
  }

  return (
    <CustomCheckoutProvider stripe={stripe} options={{ clientSecret }}>
      <PersonalFundsPaymentForm
        amount={amount}
        onComplete={onComplete}
        disabled={disabled}
      />
    </CustomCheckoutProvider>
  )
}
