import {
  CANADA_ISO2,
  INDIA_ISO2,
  MEXICO_ISO2,
  UNITES_STATES_ISO2,
  countryIso3ToIso2,
} from 'constants/countries'
import {
  ProductVariant,
  ProductVariant_SourceType,
} from 'gen/perkup/v1/product_variant_pb'
import { compact } from 'lodash-es'
import { Iso2 } from 'types/countries'
import { isEuropeanIso2 } from 'utils/countries'

interface ShippingTimeline {
  unit: 'business days' | 'weeks'
  minDuration: number
  maxDuration: number
  displayStr: string
}

const timelineFunctions: {
  id: string
  fn: (variant: ProductVariant, shippingCountryIso2: Iso2) => ShippingTimeline
  /**
   * Check function to determine if this timeline function should be applied.
   *
   * This function should only be run in the context of the array because it assumes that the previous check functions have already been executed.
   */
  check: (variant: ProductVariant, shippingCountryIso2: Iso2) => boolean
}[] = [
  {
    id: 'gift-cards',
    check: variant => {
      return variant.id.startsWith('plum_')
    },
    fn() {
      return {
        unit: 'business days',
        minDuration: 0,
        maxDuration: 0,
        displayStr: '0 to 30 minutes',
      }
    },
  },
  {
    id: 'physical-gifts',
    check: variant => {
      return variant.id.startsWith('shop_')
    },
    fn() {
      return {
        unit: 'weeks',
        minDuration: 2,
        maxDuration: 6,
        displayStr: '2-6 weeks',
      }
    },
  },
  {
    id: 'on-demand',
    check: variant => {
      return variant.sourceType !== ProductVariant_SourceType.fullPrepaid
    },
    fn(_, shippingCountryIso2) {
      if (shippingCountryIso2 === INDIA_ISO2) {
        return {
          unit: 'weeks',
          minDuration: 3,
          maxDuration: 4,
          displayStr: '3-4 weeks',
        }
      }

      if (shippingCountryIso2 === UNITES_STATES_ISO2) {
        return {
          unit: 'business days',
          minDuration: 7,
          maxDuration: 10,
          displayStr: '7-10 days',
        }
      }

      if (isEuropeanIso2(shippingCountryIso2)) {
        return {
          unit: 'business days',
          minDuration: 5,
          maxDuration: 14,
          displayStr: '5-14 days',
        }
      }

      if (
        shippingCountryIso2 === CANADA_ISO2 ||
        shippingCountryIso2 === MEXICO_ISO2
      ) {
        return {
          unit: 'weeks',
          minDuration: 2,
          maxDuration: 3,
          displayStr: '2-3 weeks',
        }
      }

      return {
        unit: 'weeks',
        minDuration: 4,
        maxDuration: 6,
        displayStr: '4-6 weeks',
      }
    },
  },
  {
    id: 'bulk-swag', // Warehoused
    check: variant => {
      return (
        !!variant.inventoryTracked && Number(variant?.inventoryQuantity) > 0
      )
    },
    fn(_, shippingCountryIso2) {
      if (shippingCountryIso2 === INDIA_ISO2) {
        return {
          unit: 'business days',
          minDuration: 5,
          maxDuration: 7,
          displayStr: '5-7 days',
        }
      }

      if (shippingCountryIso2 === UNITES_STATES_ISO2) {
        return {
          unit: 'business days',
          minDuration: 3,
          maxDuration: 7,
          displayStr: '3-7 days',
        }
      }

      if (isEuropeanIso2(shippingCountryIso2)) {
        return {
          unit: 'business days',
          minDuration: 5,
          maxDuration: 10,
          displayStr: '5-10 days',
        }
      }

      if (
        shippingCountryIso2 === CANADA_ISO2 ||
        shippingCountryIso2 === MEXICO_ISO2
      ) {
        return {
          unit: 'weeks',
          minDuration: 2,
          maxDuration: 3,
          displayStr: '2-3 weeks',
        }
      }

      return {
        unit: 'weeks',
        minDuration: 2,
        maxDuration: 4,
        displayStr: '2-4 weeks',
      }
    },
  },
  {
    id: 'bulk-and-back-order', // Back order
    check: variant => {
      return (
        !!variant.inventoryTracked && Number(variant?.inventoryQuantity) <= 0
      )
    },
    fn(_, shippingCountryIso2) {
      if (shippingCountryIso2 === INDIA_ISO2) {
        return {
          unit: 'weeks',
          minDuration: 4,
          maxDuration: 8,
          displayStr: '4-8 weeks',
        }
      }

      if (shippingCountryIso2 === UNITES_STATES_ISO2) {
        return {
          unit: 'weeks',
          minDuration: 4,
          maxDuration: 8,
          displayStr: '4-8 weeks',
        }
      }

      if (isEuropeanIso2(shippingCountryIso2)) {
        return {
          unit: 'weeks',
          minDuration: 4,
          maxDuration: 8,
          displayStr: '4-8 weeks',
        }
      }

      if (
        shippingCountryIso2 === CANADA_ISO2 ||
        shippingCountryIso2 === MEXICO_ISO2
      ) {
        return {
          unit: 'weeks',
          minDuration: 6,
          maxDuration: 8,
          displayStr: '6-8 weeks',
        }
      }

      return {
        unit: 'weeks',
        minDuration: 6,
        maxDuration: 8,
        displayStr: '6-8 weeks',
      }
    },
  },
]

/**
 *
 * @param variant
 * @param shippingCountryIso2
 * @returns ShippingTimeline if the passed in product variant is available in the desired shipping country
 * @returns undefined if the passed in product variant is not available in the desired shipping country
 */
export function estimateShippingTimeline(
  variant: ProductVariant,
  shippingCountryIso2: Iso2
): ShippingTimeline | undefined {
  const iso2VariantShippingCountries = variant.shippingCountries
    .map(c => countryIso3ToIso2[c.toLowerCase()])
    .filter(Boolean)

  // Check if variant is available on the shipping address
  if (
    iso2VariantShippingCountries.length > 0 &&
    !iso2VariantShippingCountries.includes(shippingCountryIso2.toLowerCase())
  ) {
    return undefined
  }

  const timelineFunction = timelineFunctions.find(({ check }) =>
    check(variant, shippingCountryIso2)
  )

  if (timelineFunction) {
    return timelineFunction.fn(variant, shippingCountryIso2)
  }

  return undefined
}

/**
 * Returns the quickest shipping timeline for a product variant
 * @param productVariant
 * @returns ShippingTimeline if we found a shipping timeline
 * @returns undefined if we didn't find a shipping timeline
 */
export function quickestShippingTimeline(
  productVariant: ProductVariant
): ShippingTimeline | undefined {
  const iso2VariantShippingCountries = productVariant.shippingCountries
    .map(c => countryIso3ToIso2[c.toLowerCase()])
    .filter(Boolean)

  const allTimelines = compact(
    iso2VariantShippingCountries.map(country => {
      return estimateShippingTimeline(productVariant, country as Iso2)
    })
  )
    // First sort by min duration.
    .sort((a, b) => a.minDuration - b.minDuration)
    // Now sort by unit. Business days has higher priority than weeks.
    .sort((a, b) => {
      if (a.unit === b.unit) return 0
      if (a.unit === 'business days') return -1
      return 1
    })

  if (allTimelines.length === 0) return undefined

  const quickestTimeline = allTimelines[0]
  return quickestTimeline
}
