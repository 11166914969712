import { CloseOutlined, DeleteOutlined, DownOutlined } from '@ant-design/icons'
import { Badge, Button, Flex, InputNumber, Popconfirm, Tooltip } from 'antd'
import {
  PerkIconTooltipButton,
  PerkImage,
  PerkLink,
  VariantEstimatedShippingTime,
} from 'components'
import { ExchangeRateContext } from 'context'
import { Pane, Text } from 'evergreen-ui'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useDefaultOrgColors, useDisplayCurrency } from 'hooks'
import { useContext, useState } from 'react'
import { WithSelectedQuantity } from 'types'
import { numToDollars } from 'utils'
import { buildProductVariantDisplayName } from 'utils/productVariant'

function LineItemInput({
  currentQuantity,
  productName,
  onQuantityChange,
  onRemoveClick,
}: {
  currentQuantity: number
  productName: string
  onQuantityChange: (newQuanity: number) => void
  onRemoveClick?: () => void
}) {
  const [showInput, setShowInput] = useState(false)
  const [showWarningModal, setShowWarningModal] = useState(false)
  return showInput ? (
    <Flex
      gap={4}
      align="center"
      onBlur={e => {
        if (
          e.relatedTarget === null ||
          e.relatedTarget.className.includes('ant-drawer') // Blurring doesn't work in cart drawer without this
        ) {
          setShowInput(false)
        }
      }}
    >
      <InputNumber
        value={currentQuantity}
        style={{ width: 56 }}
        autoFocus
        min={1}
        precision={0}
        onChange={n => {
          if (n) {
            onQuantityChange(n)
          }
        }}
        size="small"
      />
      {onRemoveClick && (
        <Popconfirm
          onConfirm={onRemoveClick}
          title="Remove option"
          description={`Do you want to remove ${productName}?`}
          okText="Yes"
          cancelText="No"
          onCancel={() => setShowWarningModal(false)}
          open={showWarningModal}
        >
          <PerkIconTooltipButton
            title="Remove option"
            open={showWarningModal ? false : undefined}
            type="text"
            danger
            icon={<DeleteOutlined />}
            onClick={() => setShowWarningModal(true)}
          />
        </Popconfirm>
      )}
    </Flex>
  ) : (
    <Tooltip title="Edit quantity">
      <Button
        type="text"
        style={{ padding: '1px 4px', height: 'min-content' }}
        onClick={() => setShowInput(true)}
      >
        {currentQuantity}
        <DownOutlined />
      </Button>
    </Tooltip>
  )
}

function LineItems({
  items = [],
  onQuantityChange,
  onRemoveClick,
}: {
  items: WithSelectedQuantity<ProductVariant>[]
  onQuantityChange?: (pvId: string, newQuantity: number) => void
  onRemoveClick?: (pvId: string) => void
}) {
  const displayCurrency = useDisplayCurrency()
  const exchangeRate = useContext(ExchangeRateContext)

  const productHasNoOtherVariants =
    items.length === 1 && Number(items[0].variantsCount) === 1

  if (productHasNoOtherVariants) {
    const { selectedQuantity, amount, id } = items[0]
    const productVariantAmount = numToDollars(
      Number(amount) * exchangeRate,
      2,
      false,
      displayCurrency
    )
    const total = numToDollars(
      Number(amount) * exchangeRate * (selectedQuantity || 0),
      2,
      false,
      displayCurrency
    )
    return (
      <Flex gap={8} align="center">
        <Text color="muted">{productVariantAmount}</Text>
        <Flex flex={1} gap={4} align="center">
          <Text color="muted">Qty:</Text>
          {onQuantityChange ? (
            <LineItemInput
              productName={items[0].productName}
              currentQuantity={selectedQuantity ?? 1}
              onQuantityChange={q => onQuantityChange(id, q)}
              onRemoveClick={() => {
                if (onRemoveClick) onRemoveClick(id)
              }}
            />
          ) : (
            <Text color="muted">{selectedQuantity}</Text>
          )}
        </Flex>
        <Text>{total}</Text>
      </Flex>
    )
  }

  return (
    <Flex vertical gap={4}>
      {items.map(({ amount, variantName, id, selectedQuantity }) => {
        const productVariantAmount = numToDollars(
          Number(amount) * exchangeRate,
          2,
          false,
          displayCurrency
        )
        const subTotal = numToDollars(
          Number(amount) * exchangeRate * (selectedQuantity || 0),
          2,
          false,
          displayCurrency
        )
        return (
          <Flex key={id} gap={8} align="center">
            <Flex gap={8} align="center" flex={1}>
              <Text color="muted">{productVariantAmount}</Text>
              <Flex gap={4} align="center">
                <Text color="muted">{variantName}:</Text>
                {onQuantityChange ? (
                  <LineItemInput
                    productName={buildProductVariantDisplayName({
                      productVariant: items[0],
                    })}
                    currentQuantity={selectedQuantity ?? 1}
                    onQuantityChange={q => onQuantityChange(id, q)}
                    onRemoveClick={() => {
                      if (onRemoveClick) onRemoveClick(id)
                    }}
                  />
                ) : (
                  <Text color="muted">{selectedQuantity}</Text>
                )}
              </Flex>
            </Flex>
            <Text>{subTotal}</Text>
          </Flex>
        )
      })}
    </Flex>
  )
}

/**
 *
 * TODO: Make this component compound (CD) https://linear.app/perkup/issue/ENG-7280/make-bulkproductrowcard-a-compound-component
 */
export function BulkProductRowCard({
  to,
  productVariantsWithQuantities = [],
  onRemoveProductClick,
  onRemoveVariantClick,
  onQuantityChange,
  onAfterNavigatingTo,
  elevateOnHover = false,
  withEstimatedShippingTime = false,
  shippingAddress,
  nextToProductName,
}: {
  to?: string
  productVariantsWithQuantities: WithSelectedQuantity<ProductVariant>[]
  onRemoveProductClick?: (productId: string) => void
  onRemoveVariantClick?: (pvId: string) => void
  onQuantityChange?: (pvId: string, newQuanity: number) => void
  onAfterNavigatingTo?: () => void
  elevateOnHover?: boolean
  withEstimatedShippingTime?: boolean
  shippingAddress?: ShippingAddress
  nextToProductName?: React.ReactNode
}) {
  const { defaultColor } = useDefaultOrgColors()

  const [isHovered, setIsHovered] = useState(false)

  const { productName, productImages, imageUrl, productImageUrl } =
    productVariantsWithQuantities[0]

  const totalQuantity = productVariantsWithQuantities.reduce(
    (acc, { selectedQuantity }) => acc + (selectedQuantity || 0),
    0
  )

  return (
    <Pane
      display="flex"
      position="relative"
      elevation={isHovered && elevateOnHover ? 1 : undefined}
      padding={16}
      gap={16}
      borderRadius={8}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Badge count={totalQuantity} color={defaultColor}>
        <PerkImage
          preview
          src={imageUrl || productImages?.[0]?.src || productImageUrl} // Cam specifically wants the row card to show the image of the first variant
          alt={`Small thumbnail cart image for ${productName}`}
          sizes="72px"
          style={{
            aspectRatio: '1/1',
            objectFit: 'cover',
            width: 72,
            height: 72,
            borderRadius: 8,
          }}
        />
      </Badge>

      <Flex vertical gap={4} flex={1}>
        <Flex gap={8} align="center">
          {to ? (
            <PerkLink to={to}>
              <Text className="hoverline" onClick={onAfterNavigatingTo}>
                {productName}
              </Text>
            </PerkLink>
          ) : (
            <Text>{productName}</Text>
          )}
          {nextToProductName}
        </Flex>
        <LineItems
          items={productVariantsWithQuantities}
          onQuantityChange={onQuantityChange}
          onRemoveClick={pvId => {
            if (onRemoveVariantClick) onRemoveVariantClick(pvId)
          }}
        />
        {withEstimatedShippingTime && (
          <VariantEstimatedShippingTime
            productVariant={productVariantsWithQuantities[0]}
            shippingAddress={shippingAddress}
          />
        )}
      </Flex>

      {onRemoveProductClick && isHovered && (
        <Tooltip title="Remove item">
          <Button
            size="small"
            style={{ position: 'absolute', right: 8, top: 8, borderRadius: 6 }}
            icon={<CloseOutlined />}
            onClick={() =>
              onRemoveProductClick(productVariantsWithQuantities[0].productId)
            }
          />
        </Tooltip>
      )}
    </Pane>
  )
}
