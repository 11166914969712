import { SearchOutlined } from '@ant-design/icons'
import { Breadcrumb, Button, Flex, InputNumber, InputNumberProps } from 'antd'
import { updateCartItems } from 'api/databaseCalls/writes/carts'
import {
  AddressShortDisplay,
  BackIconButton,
  PerkEmpty,
  PerkLoader,
  VariantEstimatedShippingTime,
} from 'components'
import { COLLECTION, PRODUCT_VARIANT_ID, QUANITTY } from 'constants/params'
import { CARD, DEFAULT_ROUTES, HOME } from 'constants/routes'
import { SwagCollectionIdsContext, UserShippingAddressesContext } from 'context'
import { Heading } from 'evergreen-ui'
import { ProductDetails } from 'features'
import { Cart, Cart_Item } from 'gen/perkup/v1/cart_pb'
import { ProductVariant } from 'gen/perkup/v1/product_variant_pb'
import { ShippingAddress } from 'gen/perkup/v1/root_user_pb'
import { useCollectionById } from 'hooks'
import useListAllProductVariantsByProductId from 'hooks/productVariants/useListAllProductVariantsByProductId'
import { isUndefined } from 'lodash-es'
import { useContext, useState } from 'react'
import { isMobile } from 'react-device-detect'
import { Helmet } from 'react-helmet-async'
import {
  Link,
  useNavigate,
  useOutletContext,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { WithSelectedQuantity } from 'types'
import { shouldSkipPvInventoryChecks } from 'utils/productVariant'

export function SwagStoreProductPage({
  onAddProductToCart,
}: {
  onAddProductToCart: (
    addedProductVariant: WithSelectedQuantity<ProductVariant>
  ) => void
}) {
  const navigate = useNavigate()

  const { productId } = useParams()

  const [searchParams] = useSearchParams()
  const collectionId = searchParams.get(COLLECTION) || undefined

  const swagCollections = useContext(SwagCollectionIdsContext)
  const shippingAddresses = useContext(UserShippingAddressesContext)
  const outletContext = useOutletContext() as {
    cart?: Cart
    shippingAddress?: ShippingAddress
    onShippingAddressChange: (address?: ShippingAddress) => void
  }

  const [selectedQuantity, setSelectedQuantity] = useState(1)
  const [isSavingCart, setIsSavingCart] = useState(false)
  const [selectedProductVariant, setSelectedProductVariant] =
    useState<ProductVariant>()

  const { defaultVariant, isLoadingInitial } =
    useListAllProductVariantsByProductId({
      productId,
    })

  const { collection } = useCollectionById({
    id: collectionId,
  })

  const cart = outletContext?.cart
  const lineItems = cart?.lineItems || []
  const orgHasMultipleCollections = swagCollections.length > 1

  if (isLoadingInitial) return <PerkLoader />

  if (!productId || !defaultVariant) {
    return (
      <PerkEmpty
        header="Product not found"
        description="We couldn’t find the product you were looking for."
        iconNode={<SearchOutlined style={{ fontSize: 42 }} />}
        ctaProps={{
          children: 'Go home',
          onClick: () => navigate(`${HOME}`),
          type: 'primary',
        }}
      />
    )
  }

  const handleAddProductVariantToCart = async (
    productVariant: ProductVariant
  ) => {
    if (!cart) return

    setIsSavingCart(true)

    const existingLineItemInCart = lineItems.find(
      li => li.productVariantId === productVariant.id
    )

    // If the product variant is already in the cart, update the quantity on that line item, else just add a the line item to the cart.
    const updatedLineItems = existingLineItemInCart
      ? lineItems.map(li =>
          li.productVariantId === productVariant.id
            ? Object.assign(li, {
                quantity: li.quantity + selectedQuantity,
              })
            : li
        )
      : [
          ...lineItems,
          new Cart_Item({
            productVariantId: productVariant.id,
            productId: productVariant.productId,
            quantity: selectedQuantity,
            provider: productVariant.provider,
            type: productVariant.type,
          }),
        ]

    await updateCartItems({
      cartId: cart.id,
      lineItems: updatedLineItems,
    })

    const pvWithQty = Object.assign(productVariant, {
      selectedQuantity,
    })

    onAddProductToCart(pvWithQty)
    setIsSavingCart(false)
  }

  const handleBuyNow = (product?: ProductVariant) => {
    if (!product) return
    const searchParams = new URLSearchParams({
      [PRODUCT_VARIANT_ID]: product.id,
      [QUANITTY]: selectedQuantity.toString(),
    })
    navigate({
      pathname: `${DEFAULT_ROUTES.SWAG.ROOT}/checkout`,
      search: searchParams.toString(),
    })
  }

  const handleChangeQuantity: InputNumberProps['onChange'] = value => {
    if (typeof value === 'number') setSelectedQuantity(value)
  }

  const pvForInventoryCheck = selectedProductVariant || defaultVariant

  // Disable the CTA if: Specific pv selected, inventory of pv is tracked, policy is not continue, and the selected quantity is greater than the inventory quantity
  const disableCta =
    !shouldSkipPvInventoryChecks(pvForInventoryCheck) &&
    !isUndefined(pvForInventoryCheck?.inventoryQuantity) &&
    pvForInventoryCheck.inventoryQuantity < selectedQuantity

  return (
    <>
      <Helmet>
        <title>{defaultVariant.productName}</title>
      </Helmet>
      <Flex
        vertical
        gap={32}
        style={{ margin: 'auto', marginBottom: isMobile ? 104 : undefined }}
      >
        <Flex align="center" gap={8}>
          <BackIconButton
            to={
              collection?.id
                ? `${DEFAULT_ROUTES.SWAG.ROOT}/${collection.id}`
                : DEFAULT_ROUTES.SWAG.ROOT
            }
          />
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to={CARD}>Home</Link>
            </Breadcrumb.Item>
            {orgHasMultipleCollections && (
              <Breadcrumb.Item>
                <Link to={DEFAULT_ROUTES.SWAG.ROOT}>Collections</Link>
              </Breadcrumb.Item>
            )}
            {collection && (
              <Breadcrumb.Item>
                <Link to={`${DEFAULT_ROUTES.SWAG.ROOT}/${collection.id}`}>
                  {collection.name}
                </Link>
              </Breadcrumb.Item>
            )}
            <Breadcrumb.Item>{defaultVariant.productName}</Breadcrumb.Item>
          </Breadcrumb>
        </Flex>

        <ProductDetails
          productVariant={defaultVariant}
          showPrice
          showShipping
          showShippingCountries
          submitButtonProps={{
            children: 'Add to cart',
            loading: isSavingCart,
          }}
          onSubmit={isMobile ? undefined : handleAddProductVariantToCart}
          onSelectedVariantChange={setSelectedProductVariant}
          disableSubmit={disableCta}
        >
          <ProductDetails.Slot name="aboveSubmit">
            <Flex vertical gap={4}>
              <Heading size={400}>Quantity</Heading>
              <InputNumber
                min={1}
                defaultValue={1}
                onChange={handleChangeQuantity}
              />
            </Flex>
          </ProductDetails.Slot>
          {!isMobile && (
            <ProductDetails.Slot name="nextToSubmit">
              <Button
                style={{ flex: 1 }}
                onClick={() =>
                  handleBuyNow(selectedProductVariant ?? defaultVariant)
                }
              >
                Buy now
              </Button>
            </ProductDetails.Slot>
          )}
          {outletContext?.shippingAddress && (
            <ProductDetails.Slot name="aboveOptions">
              <AddressShortDisplay
                isMuted
                shippingAddress={outletContext?.shippingAddress}
                shippingAddresses={shippingAddresses}
                onShippingAddressChange={outletContext.onShippingAddressChange}
              />
            </ProductDetails.Slot>
          )}
          <ProductDetails.Slot name="shippingEstimation">
            <VariantEstimatedShippingTime
              productVariant={defaultVariant}
              shippingAddress={outletContext?.shippingAddress}
            />
          </ProductDetails.Slot>
        </ProductDetails>
      </Flex>

      {isMobile && (
        <Flex
          gap={8}
          style={{
            position: 'fixed',
            padding: 16,
            width: '100%',
            bottom: 0,
            left: 0,
            backgroundColor: 'white',
          }}
        >
          <Button
            type="primary"
            size="large"
            style={{ flex: 1 }}
            loading={isSavingCart}
            onClick={() =>
              handleAddProductVariantToCart(
                selectedProductVariant ?? defaultVariant
              )
            }
          >
            Add to cart
          </Button>
          <Button
            size="large"
            style={{ flex: 1 }}
            onClick={() =>
              handleBuyNow(selectedProductVariant ?? defaultVariant)
            }
          >
            Buy now
          </Button>
        </Flex>
      )}
    </>
  )
}
